export const plausibleEvents = {
  login: 'log+in',
  clickDownload: 'click+download',
  clickDownloadAll: 'click+download+all',
  keyConversionDownload: 'key+conversion+download',
  keyConversionDownloadAll: 'key+conversion+download+all',
  successfulDownload: 'successful+download',
  successfulDownloadAll: 'successful+download+all',
  playDemo: 'play+demo',
  watchTutorial: 'watch+tutorial',
  filtering: 'filtering',
  sorting: 'sorting',
  search: 'search',
  sbcPopupButtonClick: 'sbc+popup+button+click',
  sbcPopupButtonClose: 'sbc+pop+up+close',
  modalClickToPreview: 'modal+click+to+preview',
  modalFilterClick: 'modal+filter+click',
  eyeIconPreviewClick: 'eye+icon+preview+click',
};
