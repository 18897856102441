import { memo, useMemo } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { extractPackItems } from 'utils/generateMaterials';

import { previewFilters } from 'components/config/downloadMaterials';

import styles from './PreviewFilters.module.scss';

const PreviewFilters = ({ materialsData, selectedFiles, onChange }) => {
  const filteredPreviews = useMemo(() => {
    const filtered = previewFilters.reduce(
      (acc, { value }) => ({
        ...acc,
        [value]: [],
      }),
      {},
    );

    const previews = !Array.isArray(materialsData)
      ? extractPackItems(materialsData)
      : materialsData;

    previews.forEach((preview) => {
      const [previewName] = preview.split('.');

      const [width, height] = previewName
        .split('x')
        .map((value) => parseInt(value));
      const ratio = width / height;

      switch (true) {
        case ratio === 1:
          filtered.square.push(preview);
          break;
        case ratio < 1:
          filtered.vertical.push(preview);
          break;
        case ratio > 1 && ratio <= 1.9:
          filtered.horizontal.push(preview);
          break;
        case ratio > 1.9:
          filtered.leaderboards.push(preview);
          break;
      }
    });

    return filtered;
  }, [materialsData]);

  return (
    <div className={styles.container} data-testid="preview-filters-root">
      {previewFilters.map(({ label, value }) => {
        const title = `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`;
        const currentPreviews = filteredPreviews[value];

        if (!currentPreviews.length) return null;

        const selected = currentPreviews.every((preview) =>
          selectedFiles.includes(preview),
        );

        return (
          <div
            key={value}
            onClick={() => onChange(value, currentPreviews, selected)}
            className={classNames(styles.preview, {
              [styles.selected]: selected,
            })}
            data-testid={`preview-filters-item-${value}`}
          >
            <div className={styles.card}>
              <div
                className={classNames(styles.value, styles[`value${value}`])}
              >
                {label}
              </div>
            </div>
            {title}
            {selected && ` (${currentPreviews.length})`}
          </div>
        );
      })}
    </div>
  );
};

PreviewFilters.propTypes = {
  materialsData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(PreviewFilters);
