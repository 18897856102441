import Account from 'components/pages/Account/Account';
import AllGames from 'components/pages/AllGames/AllGames';
import Bingo from 'components/pages/Bingo/Bingo';
import BrandAssets from 'components/pages/BrandAssets/BrandAssets';
import Contents from 'components/pages/Contents/Contents';
import Documentation from 'components/pages/Documentation/Documentation';
import DownloadMaterials from 'components/pages/DownloadMaterials/DownloadMaterials';
import Game from 'components/pages/Game/Game';
import Home from 'components/pages/Home/Home';
import { Category as NewsCategory } from 'components/pages/NewsAndUpdates/Category/Category';
import News from 'components/pages/NewsAndUpdates/News/News';
import NewsAndUpdates from 'components/pages/NewsAndUpdates/NewsAndUpdates';
import { Category as PromotionsCategory } from 'components/pages/Promotions/Category/Category';
import PromotionsItem from 'components/pages/Promotions/Item/Item';
// import Compliance from 'components/pages/Compliance/Compliance';
import Promotions from 'components/pages/Promotions/Promotions';
import Roadmap from 'components/pages/Roadmap/Roadmap';
import Support from 'components/pages/Support/Support';
import { Category as TournamentsCategory } from 'components/pages/Tournaments/Category/Category';
import TournamentsItem from 'components/pages/Tournaments/Item/Item';
import Tournaments from 'components/pages/Tournaments/Tournaments';
import Tutorials from 'components/pages/Tutorials/Tutorials';

export const BASE_PATH = '/';
export const PAGE_PATH = {
  HOME: 'home',
  ALL_GAMES: 'allGames',
  GAME: 'game',
  ROADMAP: 'roadmap',
  // COMPLIANCE: 'compliance',
  DOCUMENTATION: 'documentation',
  TUTORIALS: 'tutorials',
  NEWS: 'news',
  NEWS_CATEGORY: 'newsCategory',
  NEWS_ITEM: 'newsItem',
  BRAND_ASSETS: 'brandAssets',
  SUPPORT: 'support',
  ACCOUNT: 'account',
  PROMOTIONS: 'promotions',
  PROMOTIONS_CATEGORY: 'promotionsCategory',
  PROMOTIONS_ITEM: 'promotionsItem',
  TOURNAMENTS: 'tournaments',
  TOURNAMENTS_CATEGORY: 'tournamentsCategory',
  TOURNAMENTS_ITEM: 'tournamentsItem',
  SEARCH: 'search',
  CONTENTS: 'contents',
  BINGO: 'bingo',
};

export const Pages = {
  home: {
    path: BASE_PATH,
    element: Home,
  },
  allGames: {
    path: BASE_PATH + 'games/:provider',
    element: AllGames,
  },
  game: {
    path: BASE_PATH + 'game/:gameCode',
    element: Game,
  },
  roadmap: {
    path: BASE_PATH + 'roadmap/:provider',
    element: Roadmap,
  },
  // compliance: {
  //   path: BASE_PATH + 'compliance',
  //   element: Compliance,
  // },
  documentation: {
    path: BASE_PATH + 'documentation',
    element: Documentation,
  },
  news: {
    path: BASE_PATH + 'newsUpdates',
    element: NewsAndUpdates,
  },
  newsCategory: {
    path: BASE_PATH + 'newsUpdates/:category',
    element: NewsCategory,
  },
  newsItem: {
    path: BASE_PATH + 'newsUpdates/:category/:item',
    element: News,
  },
  brandAssets: {
    path: BASE_PATH + 'brand-assets/:provider',
    element: BrandAssets,
  },
  support: {
    path: BASE_PATH + 'support',
    element: Support,
  },
  account: {
    path: BASE_PATH + 'account',
    element: Account,
  },
  promotions: {
    path: BASE_PATH + 'promotions/:provider',
    element: Promotions,
  },
  promotionsCategory: {
    path: BASE_PATH + 'promotions/:provider/:category',
    element: PromotionsCategory,
  },
  promotionsItem: {
    path: BASE_PATH + 'promotions/:provider/:category/:item',
    element: PromotionsItem,
  },
  tournaments: {
    path: BASE_PATH + 'tournaments',
    element: Tournaments,
  },
  tournamentsCategory: {
    path: BASE_PATH + 'tournaments/:category',
    element: TournamentsCategory,
  },
  tournamentsItem: {
    path: BASE_PATH + 'tournaments/view/:item',
    element: TournamentsItem,
  },
  tutorials: {
    path: BASE_PATH + 'tutorials/:system',
    element: Tutorials,
  },
  contents: {
    path: BASE_PATH + 'content/:page',
    element: Contents,
  },
  bingo: {
    path: BASE_PATH + 'bingo',
    element: Bingo,
  },
};

export const PublicPages = {
  downloadMaterials: {
    path: `${BASE_PATH}download-materials/:type/:path`,
    Element: DownloadMaterials,
  },
};
