import { useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { findPathPages } from 'utils/pages';

import Button from 'components/UI/Button/Button';

import { PAGE_PATH } from 'components/config/pages';

import Preload from 'images/NotFound/preload.jpg';
import Vid from 'images/NotFound/video.mp4';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div
          className={classNames(styles.video, { [styles.load]: isVideoLoaded })}
        >
          <img src={Preload} alt="preload" />
          <ReactPlayer
            url={Vid}
            playing={true}
            controls={false}
            loop={true}
            muted={true}
            onReady={onLoadedData}
          />
        </div>
        <div className={styles.description}>
          The page you are looking for has flown away.
          <br />
          Our mascot, Evo, can&apos;t find it. Let&apos;s fly to a better place!
        </div>
        <Button component={Link} to={findPathPages(PAGE_PATH.HOME)}>
          Take Me Home
        </Button>
        <div className={styles.subDescription}>
          Or check out the topics on the menu on the left
        </div>
      </div>
    </div>
  );
};

export default NotFound;
