import { mergeObjects } from 'utils/mergeObjects';

import {
  THEMES,
  materialsStructure as bingoMaterialsStructure,
} from 'components/config/bingo';
import { materialsStructure as gameMaterialsStructure } from 'components/config/games';
import { getLanguages } from 'components/config/languages';
import { materialsStructure as promoMaterialsStructure } from 'components/config/promotions';

const defaultLanguage = getLanguages('en');

export const materialsConfig = {
  promo: {
    structure: promoMaterialsStructure,
    multipleFields: ['banners', 'game thumbs'],
  },
  game: {
    structure: {
      product_sheets: gameMaterialsStructure.product_sheets,
      'Promo Pack': gameMaterialsStructure['Promo Pack'],
    },
    multipleFields: ['Icons', 'Media', 'Assets', 'Video'],
    supportedLanguages: [defaultLanguage, 'Chinese'],
    defaultStructure: {
      game_data: {},
      rules: {},
    },
  },
};

export const getFileName = (path) => path.match(/([^/]+)\.[^.]+$/)?.[1];

export const getFileNamesByPath = (items, path) =>
  items.flatMap((item) => {
    if (item.includes(path)) {
      const fileName = getFileName(item);
      return fileName ? [fileName] : [];
    }

    return [];
  });

export const convertFolderPathToPrefix = (path) =>
  path.match(/\/([^/]+)\/?$/)?.[1].replace(/\+/g, ' ');

export const sortFileNames = (fileNames) =>
  [...fileNames].sort((a, b) => {
    const [aWidth, aHeight] = a.split('x');
    const [bWidth, bHeight] = b.split('x');

    return parseInt(aWidth) < parseInt(bWidth)
      ? 1
      : parseInt(aWidth) > parseInt(bWidth)
        ? -1
        : parseInt(bHeight) - parseInt(aHeight);
  });

const generateMaterialsStructure = ({
  structure,
  items,
  multipleFields,
  path = '',
}) =>
  Object.entries(structure).reduce(
    (acc, [key, { subCategory, file, pack }]) => {
      if (!items.find((item) => item === `${path}${file}`)) return acc;

      if (subCategory)
        return {
          ...acc,
          [key]: generateMaterialsStructure({
            structure: subCategory,
            items,
            multipleFields,
            path: `${path}${key}/`,
          }),
        };

      if (multipleFields.includes(key)) {
        if (pack) {
          const packItems = pack.reduce((packAcc, extension) => {
            const filteredByExtension = getFileNamesByPath(
              items,
              `${path}${key}/${extension}/`,
            );

            return {
              ...packAcc,
              ...(filteredByExtension.length && {
                [extension]: sortFileNames(filteredByExtension),
              }),
            };
          }, {});

          return {
            ...acc,
            [key]: packItems,
          };
        }

        return {
          ...acc,
          [key]: sortFileNames(getFileNamesByPath(items, `${path}${key}/`)),
        };
      }

      return { ...acc, [key]: {} };
    },
    {},
  );

export const generateDefaultMaterials = (structure) =>
  Object.entries(structure).reduce(
    (acc, [key, { subCategory, items }]) => ({
      ...acc,
      [key]:
        subCategory && !Array.isArray(subCategory)
          ? generateDefaultMaterials(subCategory)
          : (items ?? {}),
    }),
    {},
  );

const mapItemsByLanguages = (s3Items, prefix, supportedLanguages) => {
  const itemsByLanguages = s3Items.reduce((acc, { Key }) => {
    const relativePath = Key.split(prefix)[1];
    const [language, ...paths] = relativePath.split('/');

    if (
      !language ||
      (supportedLanguages && !supportedLanguages.includes(language))
    )
      return acc;

    return {
      ...acc,
      [language]: [...(acc[language] ?? []), paths.join('/')],
    };
  }, {});

  const sortedLanguageKeys = Object.keys(itemsByLanguages).sort((a, b) => {
    const aDefault = a.includes(defaultLanguage);
    const bDefault = b.includes(defaultLanguage);

    if (aDefault && !bDefault) return -1;
    if (!aDefault && bDefault) return 1;

    return a.localeCompare(b);
  });

  return sortedLanguageKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: itemsByLanguages[key],
    }),
    {},
  );
};

export const generatePromoMaterials = (s3Items, prefix) =>
  Object.entries(mapItemsByLanguages(s3Items, prefix)).reduce(
    (acc, [key, items]) => ({
      ...acc,
      [key]: generateMaterialsStructure({
        ...materialsConfig.promo,
        items,
      }),
    }),
    {},
  );

export const generateGameMaterials = (s3Items, prefix) => {
  const { supportedLanguages, defaultStructure, ...params } =
    materialsConfig.game;

  return Object.entries(
    mapItemsByLanguages(s3Items, prefix, supportedLanguages),
  ).reduce(
    (acc, [key, items]) => ({
      ...acc,
      [key]: {
        ...generateMaterialsStructure({
          ...params,
          items,
        }),
        ...defaultStructure,
      },
    }),
    {},
  );
};

export const generateMultipleGamesMaterials = (s3Items, prefixes) =>
  prefixes
    .map(
      (prefix) =>
        generateGameMaterials(
          s3Items.filter(({ Key }) => Key.startsWith(prefix)),
          prefix,
        ),
      [],
    )
    .reduce(mergeObjects);

export const generateDefaultGameMaterials = () => {
  const materials = generateDefaultMaterials(gameMaterialsStructure);

  return materialsConfig.game.supportedLanguages.reduce(
    (acc, language) => ({
      ...acc,
      [language]: materials,
    }),
    {},
  );
};

export const generateBingoMaterials = () => {
  const materials = generateDefaultMaterials(bingoMaterialsStructure);

  return THEMES.reduce(
    (acc, { name }) => ({
      ...acc,
      [name]: materials,
    }),
    {},
  );
};

export const extractPackItems = (pack) =>
  Object.entries(pack).flatMap(([key, value]) =>
    value.map((item) => `${item}.${key}`),
  );

export const getPackItemNames = (items, pack) =>
  items.flatMap((item) =>
    item.includes(`.${pack}`) ? [item.split('.')[0]] : [],
  );
