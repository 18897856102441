import { memo, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from 'components/Loader/Loader';

import { ReactComponent as CursorIcon } from 'images/Icons/Cursor.svg';
import { ReactComponent as EyeIcon } from 'images/Icons/Eye.svg';

import styles from './ImagePreview.module.scss';

const ImagePreview = ({ url, fileName }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = useCallback(() => setLoading(false), []);
  const onError = useCallback(() => setError(true), []);

  useEffect(() => {
    setLoading(true);
    setError(false);
  }, [url]);

  return (
    <div className={styles.root} data-testid="image-preview-root">
      <div className={styles.wrapper}>
        {!url ? (
          <>
            <CursorIcon />
            <div
              className={styles.title}
              data-testid="image-preview-title-empty"
            >
              Image preview
            </div>
            <div className={styles.description}>
              Click <EyeIcon className={styles.eyeIcon} /> to preview the file
              in this window
            </div>
          </>
        ) : (
          <>
            {loading && !error && <Loader />}
            {!error ? (
              <>
                <img
                  src={url}
                  onLoad={onLoad}
                  onError={onError}
                  className={classNames(styles.preview, {
                    [styles.hidden]: loading,
                  })}
                  alt={`image preview ${fileName}`}
                  data-testid="image-preview-img"
                />
              </>
            ) : (
              <>
                <div
                  className={styles.title}
                  data-testid="image-preview-title-error"
                >
                  Error: Unable to load image
                </div>
                <div className={styles.description}>
                  Please try again or contact support.
                </div>
              </>
            )}
          </>
        )}
      </div>
      {fileName && (
        <div className={styles.fileName} data-testid="image-preview-filename">
          {fileName}
        </div>
      )}
    </div>
  );
};

ImagePreview.propTypes = {
  url: PropTypes.string,
  fileName: PropTypes.string,
};

export default memo(ImagePreview);
