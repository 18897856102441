import classNames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from 'components/UI/Checkbox/Checkbox';

import styles from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Content.module.scss';

const Currency = ({
  items,
  search,
  row,
  handleClickEl,
  isCheckedWholeCategory,
  isCheckedRow,
  isCheckedEl,
}) => (
  <>
    {Object.keys(items).map((element) => {
      if (
        search &&
        element.toLowerCase().indexOf(search) === -1 &&
        items[element].toLowerCase().indexOf(search) === -1
      ) {
        return null;
      }
      return (
        <div className={styles.checkbox} key={`${row}${element}`}>
          <div
            className={classNames(
              styles.checkboxArea,
              styles.checkboxAreaCurrency,
            )}
            onClick={() => handleClickEl(row, element)}
          >
            <Checkbox
              checked={
                isCheckedWholeCategory ||
                isCheckedRow(row) ||
                isCheckedEl(row, element)
              }
            />
            <span className={styles.checkboxCurrency}>
              {element} - <span>{items[element]}</span>
            </span>
          </div>
        </div>
      );
    })}
  </>
);

Currency.propTypes = {
  items: PropTypes.shape({}).isRequired,
  row: PropTypes.string.isRequired,
  handleClickEl: PropTypes.func.isRequired,
  isCheckedRow: PropTypes.func.isRequired,
  isCheckedEl: PropTypes.func.isRequired,
  isCheckedWholeCategory: PropTypes.bool.isRequired,
  search: PropTypes.string,
};
export default Currency;
