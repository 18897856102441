import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';

import { allGamesSelector } from 'store/slices/allGames';
import {
  downloadMaterialsSelector,
  setCategory,
  setItems,
  setPack,
  setPreviewItems,
} from 'store/slices/downloadMaterials';

import { extractPackItems, getPackItemNames } from 'utils/generateMaterials';
import { triggerPlausibleEvent } from 'utils/plausible';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import Base from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Base';
import Currency from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Currency';
import ImagePreview from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/ImagePreview/ImagePreview';
import PreviewFilters from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/PreviewFilters/PreviewFilters';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import SearchInput from 'components/UI/SearchInput/SearchInput';

import { prepareMaterials } from 'components/config/games';
import { plausibleEvents } from 'components/config/plausibleEvents';

import { ReactComponent as StarsIcon } from 'images/Icons/Stars.svg';

import stylesDialog from 'components/DownloadMaterials/Dialog/Dialog.module.scss';
import styles from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Content.module.scss';

const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '9px 5px',
          '& span:after': {
            left: 9.5,
          },
        },
      },
    },
  },
});

const Content = ({
  materials,
  structure,
  category,
  type,
  provider,
  folderProps,
}) => {
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(
    structure[Object.keys(structure).find((el) => !!structure[el].open)]
      ?.category || '',
  );
  const [search, setSearch] = useState('');
  const [activePreviews, setActivePreviews] = useState({});

  const dialogData = useSelector(downloadMaterialsSelector.getDialogData);
  const downloadFilters = useSelector(downloadMaterialsSelector.getDownload);
  const selectedGames = useSelector(allGamesSelector.getSelectedItems);

  const isCheckedWholeCategory = useMemo(
    () => downloadFilters.wholeCategory.includes(category),
    [downloadFilters, category],
  );

  const isCheckedRow = useCallback(
    (row) => !!downloadFilters.category[category]?.includes(row),
    [downloadFilters, category],
  );

  const getCategoryPack = useCallback(
    (row) => downloadFilters.pack[category]?.[row],
    [downloadFilters, category],
  );

  const isCheckedPack = useCallback(
    (row, pack) => !!getCategoryPack(row)?.includes(pack),
    [getCategoryPack],
  );

  const materialsData = useMemo(
    () =>
      Object.keys(materials).length ? materials : prepareMaterials(structure),
    [materials, structure],
  );

  const handleClickCategory = useCallback(
    (row) => dispatch(setCategory({ category, row })),
    [category],
  );

  const handleClickPack = useCallback(
    (row, pack) => dispatch(setPack({ category, row, pack })),
    [category],
  );

  const handleClickEl = useCallback(
    (row, pack, el) => dispatch(setItems({ category, row, pack, el })),
    [category],
  );

  const handleClickExpand = useCallback((type) => {
    setExpand((prevState) => (prevState !== type ? type : ''));
  }, []);

  const handleClickAll = useCallback(
    (row, pack) => {
      const categoryPack = getCategoryPack(row);

      if (
        categoryPack &&
        !isCheckedPack(row, pack) &&
        Object.keys(materialsData[row]).length - 1 === categoryPack.length
      ) {
        handleClickCategory(row);
        return;
      }

      handleClickPack(row, pack);
    },
    [
      getCategoryPack,
      isCheckedPack,
      materialsData,
      handleClickCategory,
      handleClickPack,
    ],
  );

  const handleAutocomplete = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      setSearch(event.target.value.toLowerCase().trim());
    }, 500),
    [],
  );

  const createEvent = useCallback(
    (name, row, filterType = '') => {
      const providerPath = type !== 'Bingo' ? `-${provider}` : '';
      const categoryPath = category.replace(' ', '');
      const filterPath = filterType && `-${filterType}`;

      return {
        name,
        props: {
          'page-asset':
            `${type}${providerPath}-${categoryPath}-${row}${filterPath}`.toLowerCase(),
        },
      };
    },
    [type, provider, category],
  );

  const handleClickPreviewTip = useCallback(
    (row) => {
      handleClickExpand(row);
      triggerPlausibleEvent(
        createEvent(plausibleEvents.modalClickToPreview, row),
      );
    },
    [handleClickExpand, createEvent],
  );

  const handleSetActivePreview = useCallback(
    (row, preview) => {
      setActivePreviews((prev) => ({ ...prev, [row]: preview }));

      preview &&
        triggerPlausibleEvent(
          createEvent(plausibleEvents.eyeIconPreviewClick, row),
        );
    },
    [createEvent],
  );

  const selectedFiles = useMemo(() => {
    if (Array.isArray(structure)) {
      return {
        [category]: isCheckedWholeCategory
          ? materialsData
          : (downloadFilters.category[category] ?? []),
      };
    }

    const getItems = (values) =>
      !Array.isArray(values) ? extractPackItems(values) : values;

    return Object.keys(structure).reduce((acc, row) => {
      const rowData = materialsData[row];

      if (rowData) {
        if (isCheckedWholeCategory || isCheckedRow(row)) {
          return { ...acc, [row]: getItems(rowData) };
        }

        const packFiles = Object.entries(rowData).reduce(
          (packAcc, [pack, packItems]) =>
            isCheckedPack(row, pack)
              ? [
                  ...packAcc,
                  ...extractPackItems({
                    [pack]: packItems,
                  }),
                ]
              : packAcc,
          [],
        );

        const singleFiles = downloadFilters.items[category]?.[row];

        return {
          ...acc,
          [row]: [...packFiles, ...(singleFiles ? getItems(singleFiles) : [])],
        };
      }

      return {
        ...acc,
        [row]: [],
      };
    }, {});
  }, [
    structure,
    category,
    isCheckedWholeCategory,
    materialsData,
    downloadFilters,
    isCheckedRow,
    isCheckedPack,
  ]);

  const handleChangePreviewFilters = useCallback(
    (type, row, items, selected) => {
      const selectedPacks = [];

      structure[row].pack.forEach((pack) => {
        const currentSelection = getPackItemNames(selectedFiles[row], pack);
        const filterItems = getPackItemNames(items, pack);

        const allItems = selected
          ? currentSelection.filter((item) => !filterItems.includes(item))
          : [...new Set([...currentSelection, ...filterItems])];

        dispatch(
          setPreviewItems({
            category,
            row,
            pack,
            items: allItems,
            selected,
          }),
        );

        if (
          materialsData[row]?.[pack]?.every((item) => allItems.includes(item))
        ) {
          handleClickPack(row, pack);
          selectedPacks.push(pack);
        }
      });

      structure[row].pack.every((pack) => selectedPacks.includes(pack)) &&
        handleClickCategory(row);

      triggerPlausibleEvent(
        createEvent(plausibleEvents.modalFilterClick, row, type),
      );
    },
    [
      structure,
      selectedFiles,
      category,
      materialsData,
      handleClickPack,
      handleClickCategory,
      createEvent,
    ],
  );

  const previewFolder = useMemo(() => {
    const { folderPath, generateFolderPath } = folderProps;
    return generateFolderPath?.(selectedGames[0]) ?? folderPath;
  }, [folderProps, selectedGames]);

  if (Array.isArray(materialsData)) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={classNames(styles.content, styles.contentIcons6)}>
            {materialsData.map((item) => (
              <div className={styles.checkbox} key={item}>
                <div
                  className={classNames(
                    styles.checkboxArea,
                    styles.checkboxAreaCurrency,
                  )}
                  onClick={() => handleClickCategory(item)}
                >
                  <Checkbox
                    checked={isCheckedWholeCategory || isCheckedRow(item)}
                  />
                  <span className={styles.checkboxCurrency}>{item}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {Object.keys(structure).map((row) => {
        const currentMaterials = materialsData[row];
        if (!currentMaterials) return null;

        const currentMaterialsKeys = Object.keys(materialsData[row]);

        const isRowExpanded = expand === row;

        const { showPreviews } = structure[row];
        const previewsEnabled =
          dialogData.type === 'Game'
            ? showPreviews && selectedGames.length <= 1
            : showPreviews;
        const activePreview = activePreviews[row];

        const allCheckboxes =
          currentMaterialsKeys.length > 1 &&
          currentMaterialsKeys.map((pack) => {
            if (typeof currentMaterials[pack] !== 'object') return null;

            return (
              <div
                className={styles.tag}
                key={`${row}${pack}`}
                onClick={() => handleClickAll(row, pack)}
              >
                <Checkbox
                  checked={
                    isCheckedWholeCategory ||
                    isCheckedRow(row) ||
                    isCheckedPack(row, pack)
                  }
                />
                <span className={styles.label}>All {pack}</span>
              </div>
            );
          });

        const content = (
          <div
            className={classNames(
              styles.content,
              styles[
                `contentIcons${!previewsEnabled ? structure[row].countColumns : 1}`
              ],
              {
                [styles.contentPreviews]: previewsEnabled,
              },
            )}
          >
            {structure[row].component === 'Currency' && (
              <Currency
                {...structure[row]}
                search={search}
                row={row}
                handleClickEl={handleClickPack}
                isCheckedWholeCategory={isCheckedWholeCategory}
                isCheckedRow={isCheckedRow}
                isCheckedEl={isCheckedPack}
              />
            )}
            {!structure[row].component && (
              <Base
                materials={currentMaterials}
                downloadFilters={downloadFilters.items[category]}
                row={row}
                handleClickEl={handleClickEl}
                isCheckedWholeCategory={isCheckedWholeCategory}
                isCheckedPack={isCheckedPack}
                isCheckedRow={isCheckedRow}
                withPreview={previewsEnabled}
                activePreview={activePreview}
                onShowPreview={handleSetActivePreview}
              />
            )}
          </div>
        );

        return (
          <div className={styles.row} key={row}>
            <div className={styles.rowWrapper}>
              <div className={styles.header}>
                <div className={styles.title}>
                  <Checkbox
                    checked={isCheckedWholeCategory || isCheckedRow(row)}
                    onClick={() => handleClickCategory(row)}
                  />
                  <span>{structure[row].category}</span>
                  {previewsEnabled && !isRowExpanded && (
                    <div
                      className={styles.previewsTip}
                      onClick={() => handleClickPreviewTip(row)}
                    >
                      <StarsIcon className={styles.starsIcon} />
                      <span>Click to preview files</span>
                    </div>
                  )}
                </div>
                <div className={styles.expandWrapper}>
                  {structure[row].expand && (
                    <>
                      {structure[row].isAutocomplete && isRowExpanded && (
                        <SearchInput handleChange={handleAutocomplete} />
                      )}
                      {!previewsEnabled && allCheckboxes}
                      <div
                        className={classNames(stylesDialog.expand, {
                          [stylesDialog.expandActive]: isRowExpanded,
                        })}
                        onClick={() => handleClickExpand(row)}
                      >
                        <span>{structure[row].expand}</span>
                        <div className={stylesDialog.arrowWrapper}>
                          <div
                            className={classNames(stylesDialog.arrow, {
                              [stylesDialog.arrowUp]: isRowExpanded,
                              [stylesDialog.arrowDown]: !isRowExpanded,
                            })}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isRowExpanded && (
                <ThemeProvider theme={theme}>
                  {previewsEnabled && (
                    <div className={styles.filtersWrapper}>
                      <PreviewFilters
                        materialsData={materialsData[row]}
                        selectedFiles={selectedFiles[row]}
                        onChange={(type, previews, selected) =>
                          handleChangePreviewFilters(
                            type,
                            row,
                            previews,
                            selected,
                          )
                        }
                      />
                      {structure[row].pack && (
                        <div className={styles.allPack}>{allCheckboxes}</div>
                      )}
                    </div>
                  )}
                  {!previewsEnabled ? (
                    content
                  ) : (
                    <div className={styles.contentPreviewsWrapper}>
                      {content}
                      <ImagePreview
                        url={
                          activePreview &&
                          `${previewFolder}${category}/${row}${structure[row].pack?.[0] ? `/${structure[row].pack[0]}` : ''}/${activePreview}.jpg`
                        }
                        fileName={activePreview}
                      />
                    </div>
                  )}
                </ThemeProvider>
              )}
            </div>
            {previewsEnabled && isRowExpanded && (
              <div className={styles.filesSelected}>
                Files selected for download:{' '}
                <span>{selectedFiles[row].length}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

Content.propTypes = {
  materials: PropTypes.shape({}).isRequired,
  structure: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]).isRequired,
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  provider: PropTypes.string,
  folderProps: PropTypes.shape({
    folderPath: PropTypes.string.isRequired,
    generateFolderPath: PropTypes.func,
  }).isRequired,
};

export default Content;
