import Features50 from 'images/Bingo/50.jpg';
import Features75 from 'images/Bingo/75.jpg';
import Features90 from 'images/Bingo/90.jpg';
import Theme1 from 'images/Bingo/Theme1.png';
import Theme2 from 'images/Bingo/Theme2.png';
import Theme3 from 'images/Bingo/Theme3.png';
import Theme4 from 'images/Bingo/Theme4.png';
import Theme5 from 'images/Bingo/Theme5.png';

export const materialsStructure = {
  'Promo Pack': {
    file: 'Promo Pack.zip',
    category: 'Promotion Pack',
    expand: 'Files',
    open: true,
    subCategory: {
      Icons: {
        file: 'Icons.zip',
        category: 'Icons',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['JPG', 'PSD'],
        showPreviews: true,
        items: {
          JPG: [
            '1920x250',
            '1800x400',
            '1000x700',
            '945x370',
            '720x340',
            '450x330',
            '446x186',
            '440x310',
            '400x400',
            '383x200',
            '360x360',
            '360x240',
            '354x210',
            '328x192',
            '315x250',
            '315x250_2',
            '301x180',
            '300x300',
            '300x225',
            '294x400',
            '294x226',
            '292x182',
            '288x375',
            '287x193',
            '253x228',
            '250x157',
            '242x181',
            '210x210',
            '183x125',
            '160x160',
          ],
          PSD: [
            '1920x250',
            '1800x400',
            '1000x700',
            '945x370',
            '720x340',
            '450x330',
            '446x186',
            '440x310',
            '400x400',
            '383x200',
            '360x360',
            '360x240',
            '354x210',
            '328x192',
            '315x250',
            '315x250_2',
            '301x180',
            '300x300',
            '300x225',
            '294x400',
            '294x226',
            '292x182',
            '288x375',
            '287x193',
            '253x228',
            '250x157',
            '242x181',
            '210x210',
            '183x125',
            '160x160',
          ],
        },
      },
      Media: {
        file: 'Media.zip',
        category: 'Media',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['JPG', 'PSD'],
        showPreviews: true,
        items: {
          JPG: [
            '1920x1080',
            '1280x720',
            '1200x900',
            '1200x630',
            '1080x1920',
            '1080x1350',
            '1080x1080',
            '580x580',
            '332x130',
            '300x650',
            '270x270',
            '152x152',
            '63x64',
          ],
          PSD: [
            '1920x1080',
            '1280x720',
            '1200x900',
            '1200x630',
            '1080x1920',
            '1080x1350',
            '1080x1080',
            '580x580',
            '332x130',
            '300x650',
            '270x270',
            '152x152',
            '63x64',
          ],
        },
      },
      Assets: {
        file: 'Assets.zip',
        category: 'Assets',
        expand: 'Files',
        countColumns: 2,
        items: ['Background', 'Game Elements'],
      },
    },
  },
};

export const FEATURES_DATA = [
  {
    name: '90',
    description:
      'In 90-ball Bingo, each ticket features a 3x9 grid with 27 squares. The columns represent the numbers 1-9, 10-19, and so on up to 90. Randomly distributed across the tickets, the 90 numbers ensure uniqueness on each ticket, with blank squares completing the arrangement. Players have three opportunities to win: One Line, Two Lines, or Full House by matching numbers on each line.',
    media: Features90,
  },
  {
    name: '75',
    description:
      'In Bingo 75, players receive a bingo ticket with a 5x5 grid containing 25 squares. The columns are divided into groups of 15 numbers, starting from 1-15 and going up to 75. These squares feature random numbers from 1 to 75, and there\'s a "free cell" at the center. A caller randomly selects and announces numbers, and players mark them on their tickets. The goal is to complete specific patterns on the ticket, like rows, columns, or diagonals, by marking the called numbers. The first player to complete the required pattern and shout "BINGO" wins the game. Each game may feature one to five patterns, each with its own prize.',
    media: Features75,
  },
  {
    name: '50',
    description:
      'Playing a game of 50-ball bingo bears similarities to its 90-ball counterpart, albeit with some key distinctions. In 50-ball bingo, players have two options to win: either completing one line and the house, or solely the house. The primary objective is to fill in one line initially, followed by both lines to clinch the house in the first option. The number range is limited to 1-50, organised in columns of ten numbers each. Unlike 90-ball bingo, where blank spaces are common, every space in 50-ball bingo is filled with a number due to the reduced numbers and lines involved. Additionally, purchasing a strip of 5 tickets ensures coverage of all numbers, necessitating players to mark off each called number, mirroring the gameplay dynamics of 90-ball bingo.',
    media: Features50,
  },
];

export const THEMES = [
  {
    name: 'Space',
    media: Theme1,
  },
  {
    name: 'Tango',
    media: Theme2,
  },
  {
    name: 'Disco',
    media: Theme3,
  },
  {
    name: 'Dogs',
    media: Theme4,
  },
  {
    name: 'Penalty Shoot-out',
    media: Theme5,
  },
];
