import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { numberFormat } from 'utils/number';
import { findPathPages } from 'utils/pages';
import { getItemPath } from 'utils/promotions';

import Tags from 'components/pages/Promotions/Tags/Tags';

import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/pages/Promotions/Promotions.module.scss';

const Card = ({ item, category, provider }) => (
  <Link
    className={styles.item}
    to={findPathPages(PAGE_PATH.PROMOTIONS_ITEM)
      .replace(':provider', provider)
      .replace(':category', category)
      .replace(':item', getItemPath(item.folderPath))}
  >
    <img src={`${item.folderPath}main.jpg`} alt={item.title} />
    <div className={styles.title}>{item.title}</div>
    <Tags tags={item.tags} className={styles.tags} />
    <div className={styles.period}>
      {item.period[0].split(' ')[0]}
      {' - '}
      {item.period[1].split(' ')[0]}
    </div>
    <div className={styles.prize}>
      <span>Prize pool:</span>
      <span>€{numberFormat(item.prizePool)}</span>
    </div>
    <div className={styles.market}>
      <span>Market:</span>
      <span>{item.market.join(', ')}</span>
    </div>
  </Link>
);

Card.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    period: PropTypes.arrayOf(PropTypes.string).isRequired,
    prizePool: PropTypes.number.isRequired,
    market: PropTypes.arrayOf(PropTypes.string).isRequired,
    folderPath: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  category: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

export default Card;
