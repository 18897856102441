import { useCallback } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getPackStructure } from 'utils/promotions';

import Checkbox from 'components/UI/Checkbox/Checkbox';

import { ReactComponent as EyeIcon } from 'images/Icons/Eye.svg';
import { ReactComponent as EyeIconCrossed } from 'images/Icons/EyeCrossed.svg';

import styles from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Content.module.scss';

const Base = ({
  materials,
  downloadFilters,
  row,
  isCheckedWholeCategory,
  isCheckedRow,
  isCheckedPack,
  handleClickEl,
  withPreview,
  activePreview,
  onShowPreview,
}) => {
  const isCheckedEl = useCallback(
    (row, pack, el) => {
      if (downloadFilters) {
        if (downloadFilters[row]) {
          if (Array.isArray(downloadFilters[row])) {
            return downloadFilters[row].includes(el);
          }

          if (downloadFilters[row][pack]) {
            return downloadFilters[row][pack].includes(el);
          }
        }
      }

      return false;
    },
    [downloadFilters],
  );

  const getIsActivePreview = useCallback(
    (id) => id === activePreview,
    [activePreview],
  );

  const renderEyeIcon = useCallback(
    (row, previewId) => {
      if (!withPreview) return null;

      return !getIsActivePreview(previewId) ? (
        <EyeIcon
          onClick={() => onShowPreview(row, previewId)}
          className={styles.checkboxEyeIcon}
        />
      ) : (
        <EyeIconCrossed
          onClick={() => onShowPreview(row, null)}
          className={classNames(
            styles.checkboxEyeIcon,
            styles.checkboxEyeIconCrossed,
          )}
        />
      );
    },
    [withPreview, getIsActivePreview, onShowPreview],
  );

  const renderLabel = useCallback(
    (label) => (
      <div
        className={classNames(styles.checkboxLabel, {
          [styles.checkboxLabelPreview]: withPreview,
        })}
      >
        {label}
      </div>
    ),
    [withPreview],
  );

  if (Array.isArray(materials)) {
    return materials.map((label) => (
      <div key={`${row} ${label}`} className={styles.checkbox}>
        <div
          className={classNames(styles.checkboxArea, {
            [styles.checkboxAreaActive]: getIsActivePreview(label),
          })}
        >
          <div className={styles.checkboxItem} key={`${row}${label}`}>
            <Checkbox
              checked={
                isCheckedWholeCategory ||
                isCheckedRow(row) ||
                isCheckedEl(row, '', label)
              }
              onClick={() => handleClickEl(row, '', label)}
            />
          </div>
          {renderLabel(label)}
          {renderEyeIcon(row, label)}
        </div>
      </div>
    ));
  }

  return Object.keys(getPackStructure(materials)).map((label) => (
    <div key={`${row} ${label}`} className={styles.checkbox}>
      <div
        className={classNames(styles.checkboxArea, {
          [styles.checkboxAreaActive]: getIsActivePreview(label),
        })}
      >
        {Object.keys(materials).map((el) => {
          if (!materials[el].includes(label)) return null;

          return (
            <div key={`${row}${label}${el}`} className={styles.checkboxItem}>
              <Checkbox
                checked={
                  isCheckedWholeCategory ||
                  isCheckedRow(row) ||
                  isCheckedPack(row, el) ||
                  isCheckedEl(row, el, label)
                }
                onClick={() => handleClickEl(row, el, label)}
              />
              <span>{el}</span>
            </div>
          );
        })}
        {renderLabel(label)}
        {renderEyeIcon(row, label)}
      </div>
    </div>
  ));
};

Base.propTypes = {
  materials: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  row: PropTypes.string.isRequired,
  isCheckedWholeCategory: PropTypes.bool.isRequired,
  isCheckedRow: PropTypes.func.isRequired,
  isCheckedPack: PropTypes.func.isRequired,
  handleClickEl: PropTypes.func.isRequired,
  downloadFilters: PropTypes.shape({}),
  withPreview: PropTypes.bool,
  activePreview: PropTypes.string,
  onShowPreview: PropTypes.func,
};

export default Base;
