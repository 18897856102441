export const previewFilters = [
  {
    label: '16:9',
    value: 'horizontal',
  },
  {
    label: '4:5',
    value: 'vertical',
  },
  {
    label: '1:1',
    value: 'square',
  },
  {
    label: '1,91:1',
    value: 'leaderboards',
  },
];
