import { promotionsPath } from 'components/config/cloud';

export const materialsStructure = {
  presentation: {
    file: 'presentation.pdf',
    category: 'Presentation',
  },
  rules: {
    file: 'rules.docx',
    category: 'Rules',
  },
  'promo pack': {
    file: 'promo pack.zip',
    category: 'Promotion Pack',
    expand: 'Files',
    open: true,
    subCategory: {
      banners: {
        file: 'banners.zip',
        category: 'Banners',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['jpg', 'psd'],
        showPreviews: true,
      },
      'game thumbs': {
        file: 'game thumbs.zip',
        category: 'Game Thumbs',
        expand: 'Sizes',
        countColumns: 4,
      },
      gif: {
        file: 'gif.gif',
        category: 'Gif',
      },
      logo: {
        file: 'logo.zip',
        category: 'Logo',
      },
    },
  },
};

export const promoTags = {
  Network: {
    title: 'Tournament',
    color: '#F0338C',
    background: 'rgba(240, 51, 140, 0.2)',
  },
  Prize: {
    title: 'Prize Drops',
    color: '#A33EE8',
    background: 'rgba(173, 71, 235, 0.2)',
  },
  Wheel: {
    title: 'Wheel of Fortune',
    color: '#FF4C24',
    background: 'rgba(255, 76, 36, 0.3)',
  },
};

export const data = {
  evoplay: [
    {
      title: 'Endless Summer',
      folderPath: `${promotionsPath}/Endless+Summer/`,
      period: ['13.06.2023 00:01 (UTC)', '22.08.2023 23:59 (UTC)'],
      description:
        'Looking for a way to make this summer unforgettable for your gamers? Join our Endless Summer Network Tournaments and offer them a fun and competitive atmosphere that they&#39;ll love. With a variety of games and exciting prizes, they&#39;ll be hooked all summer long.',
      prizePool: 120000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network'],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament consists of 3 (three) tournaments. Seaside Days Network Tournament runs on June 13-22, Island Getaway Network Tournament - July 14-25, Sun-Kissed Party Network Tournament - August 9-22. The tournaments start at 00:01 UTC and finish at 23:59 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournaments prize pool amount is 120,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every tournament is set in EUR and might be subject to currency conversion fluctuations. For Seaside Days Network Tournament, it is 0.2 EUR, Island Getaway Network Tournament - 0.4 EUR, Sun-Kissed Party Network Tournament - 0.2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR in the corresponding month in 2023.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. Each of the tournaments has its qualified mechanic.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum three (3) days before the start of each tournament.',
      phases: [
        {
          title: 'Seaside Days',
          folderPath: `${promotionsPath}/Endless+Summer/campaigns/Seaside+Days/`,
          period: ['13.06.2023 00:01 (UTC)', '22.06.2023 23:59 (UTC)'],
          prizePool: 30000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          gamesParticipating: [
            {
              gameCode: 'penalty_shoot_out',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/PenaltyShootOut_270x270.jpg',
              name: 'Penalty Shoot-out',
            },
            {
              gameCode: 'hot_triple_sevens',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
              name: 'Hot Triple Sevens',
            },
            {
              gameCode: 'patricks_magic_field',
              icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
              name: "Patrick's Magic Field",
            },
            {
              gameCode: 'elven_princesses',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
              name: 'Elven Princesses',
            },
            {
              gameCode: 'fruit_super_nova',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
              name: 'Fruit Super Nova',
            },
            {
              gameCode: 'ice_mania',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
              name: 'Ice Mania',
            },
            {
              gameCode: 'treasure_snipes',
              icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
              name: 'Treasure-snipes',
            },
            {
              gameCode: 'hot_volcano',
              icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
              name: 'Hot Volcano',
            },
            {
              gameCode: 'inner_fire',
              icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
              name: 'Inner Fire',
            },
            {
              gameCode: 'hot_rio_nights',
              icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionights_580x580-1.jpg',
              name: 'Hot Rio Nights',
            },
            {
              gameCode: 'mega_greatest_catch',
              icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
              name: 'Mega Greatest Catch',
            },
            {
              gameCode: 'cycle_of_luck',
              icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
              name: 'Cycle of Luck',
            },
          ],
          materials: {
            English: {
              presentation: 1,
              'promo pack': {
                banners: {
                  jpg: [
                    '1200x628',
                    '1080x1080',
                    '1620x300',
                    '1920x1080',
                    '1920x400',
                    '1920x600',
                    '1950x450',
                    '2200x600',
                    '300x160',
                    '320x400',
                    '600x600',
                    '600x800',
                    '620x252',
                    '700x480',
                    '800x600',
                  ],
                  psd: [
                    '1080x1080',
                    '1200x628',
                    '1620x300',
                    '1920x1080',
                    '1920x400',
                    '1920x600',
                    '1950x450',
                    '2200x600',
                    '300x160',
                    '320x400',
                    '600x600',
                    '600x800',
                    '620x252',
                    '700x480',
                    '800x600',
                  ],
                },
                'game thumbs': ['171x256', '500x500', '640x480'],
                gif: 1,
                logo: 1,
              },
            },
          },
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 13th June 2023 and 22nd June 2023 until 23:59 UTC. <br/><br/>' +
            '3. The total tournament prize amount is 30,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR in June, 2023.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum three (3) days before the start of the tournament.',
          placeAndPrize: {
            1: 4000,
            2: 3000,
            3: 2500,
            4: 2200,
            5: 2000,
            6: 1800,
            7: 1200,
            8: 810,
            9: 500,
            '10-19': 320,
            '20-49': 125,
            '50-99': 60,
            '100-150': 40,
          },
          placeAndPrizeTotal: 30000,
        },
        {
          title: 'Island Getaway',
          folderPath: `${promotionsPath}/Endless+Summer/campaigns/Island+Getaway/`,
          period: ['14.07.2023 00:01 (UTC)', '25.07.2023 23:59 (UTC)'],
          prizePool: 40000,
          mechanic: 'Total Spins',
          minBet: 0.4,
          tags: ['Network'],
          gamesParticipating: [
            {
              gameCode: 'penalty_shoot_out_street',
              icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
              name: 'Penalty Shoot-out Street',
            },
            {
              gameCode: 'hot_triple_sevens',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
              name: 'Hot Triple Sevens',
            },
            {
              gameCode: 'patricks_magic_field',
              icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
              name: "Patrick's Magic Field",
            },
            {
              gameCode: 'elven_princesses',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
              name: 'Elven Princesses',
            },
            {
              gameCode: 'fruit_super_nova',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
              name: 'Fruit Super Nova',
            },
            {
              gameCode: 'ice_mania',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
              name: 'Ice Mania',
            },
            {
              gameCode: 'treasure_snipes',
              icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
              name: 'Treasure-snipes',
            },
            {
              gameCode: 'hot_volcano',
              icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
              name: 'Hot Volcano',
            },
            {
              gameCode: 'inner_fire',
              icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
              name: 'Inner Fire',
            },
            {
              gameCode: 'hot_rio_nights',
              icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionights_580x580-1.jpg',
              name: 'Hot Rio Nights',
            },
            {
              gameCode: 'mega_greatest_catch',
              icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
              name: 'Mega Greatest Catch',
            },
            {
              gameCode: 'cycle_of_luck',
              icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
              name: 'Cycle of Luck',
            },
            {
              gameCode: 'gold_of_sirens',
              icon: 'https://evoplay.games/wp-content/uploads/2021/06/Gold_of_Sirens_270x270-1.jpg',
              name: 'Gold of Sirens',
            },
            {
              gameCode: 'massive_luck',
              icon: 'https://evoplay.games/wp-content/uploads/2022/08/massiveluck_270x270.jpg',
              name: 'Massive Luck',
            },
          ],
          materials: {
            English: {
              presentation: 1,
              'promo pack': {
                banners: {
                  jpg: [
                    '1080x1080',
                    '1200x628',
                    '1620x300',
                    '1920x1080',
                    '1920x400',
                    '1920x600',
                    '1950x450',
                    '2200x600',
                    '300x160',
                    '320x400',
                    '600x600',
                    '600x800',
                    '620x252',
                    '700x480',
                    '800x600',
                  ],
                  psd: [
                    '1080x1080',
                    '1200x628',
                    '1620x300',
                    '1920x1080',
                    '1920x400',
                    '1920x600',
                    '1950x450',
                    '2200x600',
                    '300x160',
                    '320x400',
                    '600x600',
                    '600x800',
                    '620x252',
                    '700x480',
                    '800x600',
                  ],
                },
                'game thumbs': ['171x256', '500x500', '640x480'],
                gif: 1,
                logo: 1,
              },
            },
          },
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for twelve (12) agreed days and takes place between 00:01 UTC on 14th July 2023 and 25th July 2023 until 23:59 UTC. <br/><br/>' +
            '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,40 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR in July, 2023.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Spin accumulation. <br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum three (3) days before the start of the tournament. ',
          placeAndPrize: {
            1: 4000,
            2: 2500,
            3: 1700,
            4: 1400,
            5: 800,
            '6-15': 700,
            '16-25': 500,
            '26-35': 400,
            '36-45': 300,
            '46-55': 200,
            '56-100': 80,
            '101-150': 60,
            '151-200': 40,
          },
          placeAndPrizeTotal: 40000,
        },
        {
          title: 'Sun-Kissed Party',
          folderPath: `${promotionsPath}/Endless+Summer/campaigns/Sun-Kissed+Party/`,
          period: ['09.08.2023 00:01 (UTC)', '22.08.2023 23:59 (UTC)'],
          prizePool: 50000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          gamesParticipating: [
            {
              gameCode: 'penalty_shoot_out_street',
              icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
              name: 'Penalty Shoot-out Street',
            },
            {
              gameCode: 'hot_triple_sevens',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
              name: 'Hot Triple Sevens',
            },
            {
              gameCode: 'patricks_magic_field',
              icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
              name: "Patrick's Magic Field",
            },
            {
              gameCode: 'elven_princesses',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
              name: 'Elven Princesses',
            },
            {
              gameCode: 'fruit_super_nova',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
              name: 'Fruit Super Nova',
            },
            {
              gameCode: 'ice_mania',
              icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
              name: 'Ice Mania',
            },
            {
              gameCode: 'treasure_snipes',
              icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
              name: 'Treasure-snipes',
            },
            {
              gameCode: 'hot_volcano',
              icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
              name: 'Hot Volcano',
            },
            {
              gameCode: 'inner_fire',
              icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
              name: 'Inner Fire',
            },
            {
              gameCode: 'hot_rio_nights',
              icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionights_580x580-1.jpg',
              name: 'Hot Rio Nights',
            },
            {
              gameCode: 'mega_greatest_catch',
              icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
              name: 'Mega Greatest Catch',
            },
            {
              gameCode: 'cycle_of_luck',
              icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
              name: 'Cycle of Luck',
            },
            {
              gameCode: 'gold_of_sirens',
              icon: 'https://evoplay.games/wp-content/uploads/2021/06/Gold_of_Sirens_270x270-1.jpg',
              name: 'Gold of Sirens',
            },
            {
              gameCode: 'massive_luck',
              icon: 'https://evoplay.games/wp-content/uploads/2022/08/massiveluck_270x270.jpg',
              name: 'Massive Luck',
            },
            {
              gameCode: 'x_demon',
              icon: 'https://evoplay.games/wp-content/uploads/2023/04/X_Demon_270x270-270x251.jpg',
              name: 'X-Demon',
            },
            {
              gameCode: 'unlimited_treasures',
              icon: 'https://evoplay.games/wp-content/uploads/2023/04/Unlimited_Treasures_270x270-1.jpg',
              name: 'Unlimited Treasures',
            },
          ],
          materials: {
            English: {
              presentation: 1,
              'promo pack': {
                banners: {
                  jpg: [
                    '1080x1080',
                    '1200x628',
                    '1620x300',
                    '1920x1080',
                    '1920x400',
                    '1920x600',
                    '1950x450',
                    '2200x600',
                    '300x160',
                    '320x400',
                    '600x600',
                    '600x800',
                    '620x252',
                    '700x480',
                    '800x600',
                  ],
                  psd: [
                    '1080x1080',
                    '1200x628',
                    '1620x300',
                    '1920x1080',
                    '1920x400',
                    '1920x600',
                    '1950x450',
                    '2200x600',
                    '300x160',
                    '320x400',
                    '600x600',
                    '600x800',
                    '620x252',
                    '700x480',
                    '800x600',
                  ],
                },
                'game thumbs': ['171x256', '500x500', '640x480'],
                gif: 1,
                logo: 1,
              },
            },
          },
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for fourteen (14) agreed days and takes place between 00:01 UTC on 9h August 2023 and 22nd August 2023 until 23:59 UTC. <br/><br/>' +
            '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 250 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR in August, 2023.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum three (3) days before the start of the tournament. ',
          placeAndPrize: {
            1: 5000,
            2: 4000,
            3: 3000,
            4: 1500,
            5: 1000,
            6: 900,
            7: 800,
            8: 700,
            9: 600,
            10: 500,
            '11-25': 400,
            '26-50': 200,
            '51-150': 125,
            '151-250': 85,
          },
          placeAndPrizeTotal: 50000,
        },
      ],
    },
    {
      title: 'Mojito Dance',
      folderPath: `${promotionsPath}/Mojito+Dance/`,
      period: ['15.08.2023 00:01 (UTC)', '24.08.2023 23:59 (UTC)'],
      description:
        'It&#39;s high time to catch some rays of summer entertainment with Mojito Dance Network Tournament! Invite your players to experience the waves of fun with their all-time favs - Evoplay&#39;s gaming top performers!',
      prizePool: 15000,
      market: ['MGA'],
      mechanic: 'Total Spins',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'fruit_super_nova_100',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/fruitsupernova100_270x270.jpg',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'mega_greatest_catch',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch',
        },
        {
          gameCode: 'the_greatest_catch',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270.jpg',
          name: 'The Greatest Catch',
        },
        {
          gameCode: 'inner_fire',
          icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
          name: 'Inner Fire',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'triple_chili',
          icon: 'https://evoplay.games/wp-content/uploads/2021/09/TripleChili_270x270.jpg',
          name: 'Triple Chili',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'irish_reels',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-5.jpg',
          name: 'Irish Reels',
        },
        {
          gameCode: 'book_of_rest',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/BookOfRest_270x270.jpg',
          name: 'Book of Rest',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'indianas_quest',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/IndianasQuest_270x270.jpg',
          name: "Indiana's Quest",
        },
        {
          gameCode: 'candy_dreams_sweet_planet',
          icon: 'https://evoplay.games/wp-content/uploads/2022/06/Candydreamssweetplanet_270x270.png',
          name: 'Candy Dreams: Sweet Planet',
        },
        {
          gameCode: 'budai_reels',
          icon: 'https://evoplay.games/wp-content/uploads/2022/01/budaireels_270x270.jpg',
          name: 'Budai Reels',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'wonder_farm',
          icon: 'https://evoplay.games/wp-content/uploads/2023/03/wonderfarm_270x270.jpg',
          name: 'Wonder Farm',
        },
        {
          gameCode: 'hot_rio_nights',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionights_580x580-1.jpg',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'book_of_the_priestess',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/Book_of_the_Priestess_270x270-1.jpg',
          name: 'Book of the Priestess',
        },
        {
          gameCode: 'funny_hunting',
          icon: 'https://evoplay.games/wp-content/uploads/2023/02/funnyhunting_270x270.jpg',
          name: 'Funny Hunting',
        },
        {
          gameCode: 'massive_luck',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/massiveluck_270x270.jpg',
          name: 'Massive Luck',
        },
        {
          gameCode: 'camino_de_chili',
          icon: 'https://evoplay.games/wp-content/uploads/2022/06/caminodechili_270x270.jpg',
          name: 'Camino de Chili',
        },
        {
          gameCode: 'wolf_hiding',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/Wolfhiding_270x270.png',
          name: 'Wolf Hiding',
        },
        {
          gameCode: 'penalty_shoot_out',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/PenaltyShootOut_270x270.jpg',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'gold_of_sirens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/06/Gold_of_Sirens_270x270-1.jpg',
          name: 'Gold of Sirens',
        },
        {
          gameCode: 'cycle_of_luck',
          icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'candy_dreams',
          icon: '',
          name: 'Candy Dreams',
        },
        {
          gameCode: 'temple_of_dead',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/TempleOfDead_270x270.jpg',
          name: 'Temple of Dead',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 15th August 2023 and 24th August 2023 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 15,000 EUR and will be distributed among the top 100 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR in August, 2023.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation. <br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion in accordance with the currency exchange rate at the day of payout.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum three (3) days before the start of the tournament.',
      placeAndPrize: {
        1: 1500,
        2: 1320,
        3: 1200,
        4: 1140,
        5: 1020,
        6: 840,
        7: 600,
        8: 480,
        9: 264,
        '10-19': 210,
        '20-49': 90,
        '50-100': 36,
      },
      placeAndPrizeTotal: 15000,
    },
    {
      title: 'Sunrise Luck',
      folderPath: `${promotionsPath}/Sunrise+Luck/`,
      period: ['19.06.2023 00:01 (UTC+7)', '30.08.2023 23:59 (UTC+7)'],
      description:
        'The sun is shining, and the waves are calling! Invite your players to join our Sunrise Luck Network Tournament. With games that are perfect for a day in the sun and prizes that will make the leisure time even more enjoyable, the gamers won&#39;t want to miss it out. Sign up now and let the beach party begin!',
      prizePool: 60000,
      market: ['Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/PenaltyShootOut_270x270.jpg',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'indianas_quest',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/IndianasQuest_270x270.jpg',
          name: "Indiana's Quest",
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'inner_fire',
          icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
          name: 'Inner Fire',
        },
        {
          gameCode: 'epic_gladiators',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/egpreview.jpg',
          name: 'Epic Gladiators',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'cycle_of_luck',
          icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament consists of 3 (three) Phases, which take place on June 19-28, July 18-27, August 21-30. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournaments prize pool amount is 60,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR in the corresponding month in 2023.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum three (3) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Sunrise+Luck/campaigns/Phase+1/`,
          period: ['19.06.2023 00:01 (UTC+7)', '28.06.2023 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 4680,
            2: 2340,
            3: 1170,
            4: 702,
            5: 468,
            '6-15': 350,
            '16-25': 234,
            '26-35': 116,
            '36-45': 70,
            '46-55': 46,
            '56-100': 24,
            '101-200': 14,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Sunrise+Luck/campaigns/Phase+2/`,
          period: ['18.07.2023 00:01 (UTC+7)', '27.07.2023 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 4680,
            2: 2340,
            3: 1170,
            4: 702,
            5: 468,
            '6-15': 350,
            '16-25': 234,
            '26-35': 116,
            '36-45': 70,
            '46-55': 46,
            '56-100': 24,
            '101-200': 14,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 3',
          folderPath: `${promotionsPath}/Sunrise+Luck/campaigns/Phase+3/`,
          period: ['21.08.2023 00:01 (UTC+7)', '30.08.2023 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 4680,
            2: 2340,
            3: 1170,
            4: 702,
            5: 468,
            '6-15': 350,
            '16-25': 234,
            '26-35': 116,
            '36-45': 70,
            '46-55': 46,
            '56-100': 24,
            '101-200': 14,
          },
          placeAndPrizeTotal: 20000,
        },
      ],
    },
    {
      title: 'Moon Star',
      folderPath: `${promotionsPath}/Moon+Star/`,
      period: ['05.09.2023 00:01 (UTC)', '14.09.2023 23:59 (UTC)'],
      description:
        'Embrace the autumn season with Evoplay&#39;s Moon Star Network Tournament, designed to ignite engagement among your players with our gaming superstars! Delight your audience of iGamers with an unparalleled experience where they can compete for an inspiring reward and enjoy exhilarating entertainment catering to all preferences.',
      prizePool: 40000,
      market: ['Europe'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2021/12/christmasreach_270x270-1.jpg',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: '',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'goddess_of_the_night',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/goddessofthenight_270x270.jpg',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'penalty_shoot_out',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/PenaltyShootOut_270x270.jpg',
          name: 'Penalty Shoot-out',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 5h September 2023 and 14th September 2023 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR in September, 2023.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. ',
      placeAndPrize: {
        1: 4000,
        2: 2400,
        3: 1800,
        4: 1400,
        5: 900,
        '6-10': 700,
        '11-20': 500,
        '21-30': 400,
        '31-40': 300,
        '41-60': 200,
        '61-100': 100,
        '101-150': 80,
        '151-200': 40,
      },
      placeAndPrizeTotal: 40000,
    },
    {
      title: 'Golden Treasures',
      folderPath: `${promotionsPath}/Golden+Treasures/`,
      period: ['20.09.2023 00:01 (UTC+7)', '19.10.2023 23:59 (UTC+7)'],
      description:
        'Searching for a way to make this autumn truly stellar for your gamers? Join the Golden Treasures Network Tournament! With an array of captivating games and fantastic rewards, players will be captivated all tournament. Enrol today and make this autumn a gaming experience they&#39;ll never forget!',
      prizePool: 70000,
      market: ['Asia'],
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'cycle_of_luck',
          icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'temple_of_thunder',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/Templeofthunder_270x270.jpg',
          name: 'Temple of Thunder',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'candy_dreams',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/CandyDreams_270x270-270x251.jpg',
          name: 'Candy Dreams',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/02/budaireelsbonusbuy_270x270-270x251.jpg',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'talismans_of_fortune',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/TalismanOfFortune_270x270.jpg',
          name: 'Talismans of Fortune',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament consists of 2 (two) Phases, which take place on September 19-28 , October 10-19. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournaments prize pool amount is 70,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR in the corresponding month in 2023.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Golden+Treasures/campaigns/Phase+1/`,
          period: ['19.09.2023 00:01 (UTC+7)', '28.09.2023 23:59 (UTC+7)'],
          prizePool: 35000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 3500,
            2: 1575,
            3: 1400,
            4: 1225,
            5: 700,
            '6-10': 560,
            '11-20': 490,
            '21-30': 350,
            '31-40': 245,
            '41-60': 175,
            '61-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Golden+Treasures/campaigns/Phase+2/`,
          period: ['10.10.2023 00:01 (UTC+7)', '19.10.2023 23:59 (UTC+7)'],
          prizePool: 35000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 3500,
            2: 1575,
            3: 1400,
            4: 1225,
            5: 700,
            '6-10': 560,
            '11-20': 490,
            '21-30': 350,
            '31-40': 245,
            '41-60': 175,
            '61-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
      ],
    },
    {
      title: 'Flame of Fortune',
      folderPath: `${promotionsPath}/Flame+of+Fortune/`,
      period: ['10.10.2023 00:01 (UTC)', '17.10.2023 23:59 (UTC)'],
      description:
        'To make this October very special, we are running a Flame of Fortune Network Tournament! Invite your players on fancy a date with beloved games and a sweet treat of 15,000 EUR! Are you with us?',
      prizePool: 15000,
      market: ['MGA'],
      mechanic: 'Total Win',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/caminodechilibonusbuy_270x270-1.jpg',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionightsbonusbuy_270x270.jpg',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2021/08/Gold_of_Sirens_270x270-1.jpg',
          name: 'Gold of Sirens Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'triple_chili',
          icon: 'https://evoplay.games/wp-content/uploads/2021/09/TripleChili_270x270.jpg',
          name: 'Triple Chili',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'mega_greatest_catch',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'budai_reels',
          icon: 'https://evoplay.games/wp-content/uploads/2022/01/budaireels_270x270.jpg',
          name: 'Budai Reels',
        },
        {
          gameCode: 'juicy_gems',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygems_270x270.jpg',
          name: 'Juicy Gems',
        },
        {
          gameCode: 'cycle_of_luck',
          icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'penalty_shoot_out',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/PenaltyShootOut_270x270.jpg',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'courier_sweeper',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/CourierSweeper_270x270-270x251.jpg',
          name: 'Courier Sweeper',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for eight (8) agreed days and takes place between 00:01 UTC on 10th October 2023 and 17th October 2023 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 15,000 EUR and will be distributed among the top 100 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven(7) days before the start of the tournament.',
      placeAndPrize: {
        1: 1800,
        2: 1500,
        3: 1230,
        4: 1020,
        5: 825,
        6: 675,
        7: 540,
        8: 420,
        9: 315,
        10: 225,
        '11-20': 150,
        '21-50': 90,
        '51-100': 45,
      },
      placeAndPrizeTotal: 15000,
    },
    {
      title: 'Hall of Win Network Campaign',
      folderPath: `${promotionsPath}/Hall+of+Win/`,
      period: ['20.10.2023 00:01 (UTC)', '31.10.2023 23:59 (UTC)'],
      description:
        'Embrace the spooky magic of October with the Hall of Win Network Campaign - a Halloween-themed show. Participate in spine-chilling challenges within our Network Tournament and brace yourself for ghostly surprises in our Prize Drops Campaign. This October, we&#39;re brewing a cauldron of excitement that will leave you spellbound!',
      prizePool: 60000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'northern_temple_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/Northern_temple_bonus_buy_270x270-270x251.jpg',
          name: 'Northern Temple Bonus Buy',
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'inner_fire',
          icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
          name: 'Inner Fire',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/02/budaireelsbonusbuy_270x270-270x251.jpg',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'lucky_porker_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/luckyporkerbonusbuy_270x270-270x251.jpg',
          name: 'Lucky Porker Bonus Buy',
        },
        {
          gameCode: 'elven_princesses_crown_quest',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/elvenprincessescrownquest_270x270.jpg',
          name: 'Elven Princesses: Crown Quest',
        },
        {
          gameCode: 'thunder_mega_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/thundermegasevens_270x270.jpg',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'europe_transit',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/Europe_transit_270x270-270x251.jpg',
          name: 'Europe Transit',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2021/12/christmasreach_270x270-1.jpg',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'fruit_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-2.jpg',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'europe_transit_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Europe_transit_bonus_buy_270x270-270x251.jpg',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/05/X_Demon_bonus_buy_270x270.jpg',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'lucky_porker',
          icon: 'https://evoplay.games/wp-content/uploads/2023/07/luckyporker_270x270-270x251.jpg',
          name: 'Lucky Porker',
        },
        {
          gameCode: 'neon_capital_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/neoncapitalbonusbuy_270x270.jpg',
          name: 'Neon Capital Bonus Buy',
        },
        {
          gameCode: 'goddess_of_the_night',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/goddessofthenight_270x270.jpg',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/caminodechilibonusbuy_270x270-1.jpg',
          name: 'Camino de Chili Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Campaign is available to play in real mode only and consist of two campaigns that happen simultaneously: Hall of Win Network Tournament and Hall of Win Prize Drops.<br/><br/>' +
        '2. Evoplay Network Campaign runs for (12) agreed days and takes place between 00:01 UTC on 20th October 2023 and 31th October 2023 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 60,000 EUR. <br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the tournament minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Hall of Win Network Tournament',
          folderPath: `${promotionsPath}/Hall+of+Win/`,
          period: ['20.10.2023 00:01 (UTC)', '31.10.2023 23:59 (UTC)'],
          prizePool: 30000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for twelve (12) agreed days and takes place between 00:01 UTC on 20th October 2023 and 31st October 2023 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 30,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
          placeAndPrize: {
            1: 3000,
            2: 2400,
            3: 1800,
            4: 1650,
            5: 1500,
            6: 1350,
            7: 1200,
            8: 1050,
            9: 900,
            10: 600,
            '11-20': 300,
            '21-50': 135,
            '51-100': 90,
            '101-150': 60,
          },
          placeAndPrizeTotal: 30000,
        },
        {
          title: 'Hall of Win Prize Drops',
          folderPath: `${promotionsPath}/Hall+of+Win/`,
          period: ['20.10.2023 00:01 (UTC)', '31.10.2023 23:59 (UTC)'],
          prizePool: 30000,
          mechanic: 'Random Prize Drops',
          minBet: 0.2,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Hall of Win  Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Hall of Win Prize Drops Campaign runs for twelve(12) agreed days and takes place between 00:01 UTC on 20th October 2023 and 31th October 2023 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 30,000 EUR divided into 600 prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,2 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            2: 200,
            4: 100,
            5: 80,
            8: 60,
            10: 40,
            21: 20,
          },
          placeAndPrizeTotal: 30000,
          prizesTotal: '2 500 EUR / 50 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Hall of Win',
      folderPath: `${promotionsPath}/Hall+of+Win+Network+Tournament/`,
      period: ['20.10.2023 00:01 (UTC)', '31.10.2023 23:59 (UTC)'],
      description:
        'As the leaves change and the nights grow longer, the spooky season is at the door, and Evoplay&#39;s Hall of Win Network Tournament brings the spirit of Halloween with a basket of treats for players, featuring our best games and a chance to win from the 30,000 EUR prize pool. Let your players fall into fun - add this autumnal event to your casino!',
      prizePool: 30000,
      market: ['Europe'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'northern_temple_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/Northern_temple_bonus_buy_270x270-270x251.jpg',
          name: 'Northern Temple Bonus Buy',
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'inner_fire',
          icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
          name: 'Inner Fire',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/02/budaireelsbonusbuy_270x270-270x251.jpg',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'lucky_porker_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/luckyporkerbonusbuy_270x270-270x251.jpg',
          name: 'Lucky Porker Bonus Buy',
        },
        {
          gameCode: 'elven_princesses_crown_quest',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/elvenprincessescrownquest_270x270.jpg',
          name: 'Elven Princesses: Crown Quest',
        },
        {
          gameCode: 'thunder_mega_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/thundermegasevens_270x270.jpg',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'europe_transit',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/Europe_transit_270x270-270x251.jpg',
          name: 'Europe Transit',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2021/12/christmasreach_270x270-1.jpg',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'fruit_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-2.jpg',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'europe_transit_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Europe_transit_bonus_buy_270x270-270x251.jpg',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/05/X_Demon_bonus_buy_270x270.jpg',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'lucky_porker',
          icon: 'https://evoplay.games/wp-content/uploads/2023/07/luckyporker_270x270-270x251.jpg',
          name: 'Lucky Porker',
        },
        {
          gameCode: 'neon_capital_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/neoncapitalbonusbuy_270x270.jpg',
          name: 'Neon Capital Bonus Buy',
        },
        {
          gameCode: 'goddess_of_the_night',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/goddessofthenight_270x270.jpg',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/caminodechilibonusbuy_270x270-1.jpg',
          name: 'Camino de Chili Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for twelve (12) agreed days and takes place between 00:01 UTC on 20th October 2023 and 31st October 2023 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 30,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1800,
        4: 1650,
        5: 1500,
        6: 1350,
        7: 1200,
        8: 1050,
        9: 900,
        10: 600,
        '11-20': 300,
        '21-50': 135,
        '51-100': 90,
        '101-150': 60,
      },
      placeAndPrizeTotal: 30000,
    },
    {
      title: 'Twilight Triumph Network Campaign',
      folderPath: `${promotionsPath}/Twilight+Triumph+Network+Campaign/`,
      period: ['14.11.2023 00:01 (UTC)', '27.11.2023 23:59 (UTC)'],
      description:
        'Step into the mystical realm of our Twilight Triumph Network Campaign, where November&#39;s Black Friday fervour meets a thrilling fusion of competition and chance. Take part in intense challenges in our Network Tournament and get ready for delightful surprises in our Random Prize Drop Campaign. This November, we&#39;re transforming the usual into something extraordinary, offering an experience beyond your wildest imagination!',
      prizePool: 70000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
          name: 'Penalty Shoot-Out Street',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-2.jpg',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'inner_fire',
          icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
          name: 'Inner Fire',
        },
        {
          gameCode: 'triple_chili',
          icon: 'https://evoplay.games/wp-content/uploads/2021/09/TripleChili_270x270.jpg',
          name: 'Triple Chili',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/02/budaireelsbonusbuy_270x270-270x251.jpg',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'europe_transit_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Europe_transit_bonus_buy_270x270-270x251.jpg',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'lucky_porker_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/luckyporkerbonusbuy_270x270-270x251.jpg',
          name: 'Lucky Porker Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2021/12/christmasreach_270x270-1.jpg',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/05/X_Demon_bonus_buy_270x270.jpg',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'neon_capital_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/neoncapitalbonusbuy_270x270.jpg',
          name: 'Neon Capital Bonus Buy',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'thunder_mega_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/thundermegasevens_270x270.jpg',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'goddess_of_the_night',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/goddessofthenight_270x270.jpg',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'catch_the_wind',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Catch_the_wind_270x270.jpg',
          name: 'Catch the Wind',
        },
        {
          gameCode: 'hot_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Catch_the_wind_270x270.jpg',
          name: 'Hot Mania',
        },
        {
          gameCode: 'hot_rio_nights',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionights_580x580-1.jpg',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'gold_of_sirens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/06/Gold_of_Sirens_270x270-1.jpg',
          name: 'Gold of Sirens',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Campaign is available to play in real mode only and consist of two campaigns that happen in sequence: Twilight Triumph Network Tournament and Twilight Triumph Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Network Campaign runs for (14) agreed days and takes place between 00:01 UTC on 14th November 2023 and 27th November 2023 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 70,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the tournament minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Twilight Triumph Network Tournament',
          folderPath: `${promotionsPath}/Twilight+Triumph+Network+Campaign/campaigns/Twilight+Triumph+Network+Tournament/`,
          period: ['14.11.2023 00:01 (UTC)', '20.11.2023 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Spin',
          minBet: 0.4,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 14th November 2023 and 20th November 2023 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,40 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Twilight Triumph Prize Drops',
          folderPath: `${promotionsPath}/Twilight+Triumph+Network+Campaign/campaigns/Twilight+Triumph+Prize+Drops/`,
          period: ['21.11.2023 00:01 (UTC)', '27.11.2023 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Random Prize Drops',
          minBet: 0.4,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Twilight Triumph Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Twilight Triumph Prize Drops Campaign runs for  seven (7) agreed days and takes place between 00:01 UTC on 21st November 2023 and 27th November 2023 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 35,000 EUR divided into 1400 prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            1: 400,
            2: 200,
            7: 100,
            10: 80,
            20: 40,
            60: 15,
            100: 10,
          },
          placeAndPrizeTotal: 35000,
          prizesTotal: '5 000 EUR / 200 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Twilight Triumph',
      folderPath: `${promotionsPath}/Twilight+Triumph+Network+Tournament/`,
      period: ['14.11.2023 00:01 (UTC)', '20.11.2023 23:59 (UTC)'],
      description:
        'Are you ready to illuminate your network with the brilliance of a full moon on Black Friday this November? Step into the captivating realm of the Twilight Triumph Network Tournament, where a world of competitive excitement awaits! Provide your participants the chance to test their skills and win big in electrifying challenges. Seize this opportunity to make November an unforgettable milestone for your network!',
      prizePool: 35000,
      market: ['Europe'],
      mechanic: 'Total Spin',
      minBet: 0.4,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
          name: 'Penalty Shoot-Out Street',
        },
        {
          gameCode: 'hot_volcano',
          icon: 'https://evoplay.games/wp-content/uploads/2022/07/hotvolkano_270x270.jpg',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-3.jpg',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/publicpreview-2.jpg',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'inner_fire',
          icon: 'https://evoplay.games/wp-content/uploads/2022/12/innerfire_270x270.jpg',
          name: 'Inner Fire',
        },
        {
          gameCode: 'triple_chili',
          icon: 'https://evoplay.games/wp-content/uploads/2021/09/TripleChili_270x270.jpg',
          name: 'Triple Chili',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/08/Megagreatestcatch_270x270.jpg',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/04/Thegreatestcatch_270x270-1.jpg',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/02/budaireelsbonusbuy_270x270-270x251.jpg',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'europe_transit_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Europe_transit_bonus_buy_270x270-270x251.jpg',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'lucky_porker_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/luckyporkerbonusbuy_270x270-270x251.jpg',
          name: 'Lucky Porker Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2021/12/christmasreach_270x270-1.jpg',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/05/X_Demon_bonus_buy_270x270.jpg',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'neon_capital_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/neoncapitalbonusbuy_270x270.jpg',
          name: 'Neon Capital Bonus Buy',
        },
        {
          gameCode: 'treasure_snipes',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/treasuresnipes_270x270.jpg',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'thunder_mega_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/thundermegasevens_270x270.jpg',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'goddess_of_the_night',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/goddessofthenight_270x270.jpg',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'catch_the_wind',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Catch_the_wind_270x270.jpg',
          name: 'Catch the Wind',
        },
        {
          gameCode: 'hot_mania',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Catch_the_wind_270x270.jpg',
          name: 'Hot Mania',
        },
        {
          gameCode: 'hot_rio_nights',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionights_580x580-1.jpg',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'gold_of_sirens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/06/Gold_of_Sirens_270x270-1.jpg',
          name: 'Gold of Sirens',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 14th November 2023 and 20th November 2023 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,40 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
      placeAndPrize: {
        1: 3500,
        2: 2800,
        3: 2450,
        4: 2100,
        5: 1750,
        6: 1400,
        7: 1155,
        8: 980,
        9: 910,
        10: 455,
        '11-20': 280,
        '21-50': 140,
        '51-100': 105,
        '101-150': 70,
        '151-200': 35,
      },
      placeAndPrizeTotal: 35000,
    },
    {
      title: 'Golden Buys',
      folderPath: `${promotionsPath}/Golden+Buys/`,
      period: ['17.11.2023 00:01 (UTC+7)', '28.11.2023 23:59 (UTC+7)'],
      description:
        'Are you prepared to shine as brightly as gold this November? Step into the dazzling domain of the Golden Buys Network Tournament, where an electrifying atmosphere of competition awaits you! Don&#39;t miss this chance to make November a golden month for your casino!',
      prizePool: 35000,
      market: ['Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          icon: 'https://evoplay.games/wp-content/uploads/2023/06/penaltyshootoutstreet_270x270.jpg',
          name: 'Penalty Shoot-Out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/HotTripleSevens_270x270.jpg',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'candy_dreams',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/CandyDreams_270x270-270x251.jpg',
          name: 'Candy Dreams',
        },
        {
          gameCode: 'talismans_of_fortune',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/TalismanOfFortune_270x270.jpg',
          name: 'Talismans of Fortune',
        },
        {
          gameCode: 'scratch_match',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ScratchMatch_270x270.jpg',
          name: 'Scratch Match',
        },
        {
          gameCode: 'elven_princesses',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/ElvenPrincess_270x270.jpg',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          icon: 'https://evoplay.games/wp-content/uploads/2021/03/FruitSuperNova_580x580.jpg',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'cycle_of_luck',
          icon: 'https://evoplay.games/wp-content/uploads/2021/04/CycleOfLuck_270x270.jpg',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          icon: 'https://evoplay.games/wp-content/uploads/2021/11/hottriplesevensspecial_270x270-1.jpg',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'patricks_magic_field',
          icon: 'https://evoplay.games/wp-content/uploads/2022/03/patricksmagicfield_270x270.jpg',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'jhana_of_god',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Jhana_of_god_270x270.jpg',
          name: 'Jhana of God',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          icon: 'https://evoplay.games/wp-content/uploads/2022/02/budaireelsbonusbuy_270x270-270x251.jpg',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/05/juicygemsbonusbuy_270x270.jpg',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2022/10/hotvolkanobonusbuy_270x270.jpg',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/hotrionightsbonusbuy_270x270.jpg',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/01/SoldItbonusbuy_270x270.jpg',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/05/X_Demon_bonus_buy_270x270.jpg',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          icon: 'https://evoplay.games/wp-content/uploads/2023/08/Jhana_of_god_bonus_buy270x270.jpg',
          name: 'Jhana of God Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for twelve (12) agreed days and takes place between 00:01 +7 UTC on 17th November 2023 and 28th November 2023 until 23:59 +7 UTC .<br/><br/>' +
        '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
      placeAndPrize: {
        1: 3500,
        2: 1575,
        3: 1400,
        4: 1225,
        5: 700,
        '6-10': 560,
        '11-20': 490,
        '21-30': 350,
        '31-40': 245,
        '41-60': 175,
        '61-100': 105,
        '101-150': 70,
        '151-200': 35,
      },
      placeAndPrizeTotal: 35000,
    },
    {
      title: 'Jingle Bet Network Campaign',
      folderPath: `${promotionsPath}/Jingle+Bet+Network+Campaign/`,
      period: ['12.12.2023 00:01 (UTC)', '21.12.2023 23:59 (UTC)'],
      description:
        'Get set to fun all the way with the Jingle Bet Network Campaign! This festive celebration merges the excitement of our Network Tournament with the surprise of our Random Prize Drop Campaign. Join us in spreading holiday joy and winning big this December. With delightful surprises at every turn, this campaign promises to make your season merry and bright!',
      prizePool: 70000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'treasure_snipes_christmas',
          name: 'Treasure-snipes: Christmas',
        },
        {
          gameCode: 'christmas_reach',
          name: 'Christmas Reach',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Campaign is available to play in real mode only and consist of two campaigns: Jingle Bet Network Tournament and Jingle Bet Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Network Campaign runs for (10) agreed days and takes place between 00:01 UTC on 12th December 2023 and 21st December 2023 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 70,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the tournament minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Jingle Bet Network Tournament',
          folderPath: `${promotionsPath}/Jingle+Bet+Network+Campaign/campaigns/Jingle+Bet+Network+Tournament/`,
          period: ['12.12.2023 00:01 (UTC)', '21.12.2023 23:59 (UTC)'],
          prizePool: 50000,
          mechanic: 'Sum Multiplier',
          minBet: 0.25,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 12th December 2023 and 21st December 2023 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 300 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 4500,
            2: 4000,
            3: 3500,
            4: 3000,
            5: 2500,
            6: 1500,
            7: 1250,
            8: 1000,
            9: 750,
            10: 500,
            '11-20': 250,
            '21-50': 200,
            '51-100': 150,
            '101-150': 100,
            '151-200': 70,
            '201-300': 30,
          },
          placeAndPrizeTotal: 50000,
        },
        {
          title: 'Jingle Bet Prize Drops',
          folderPath: `${promotionsPath}/Jingle+Bet+Network+Campaign/campaigns/Jingle+Bet+Prize+Drops/`,
          period: ['12.12.2023 00:01 (UTC)', '21.12.2023 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Random Prize Drops',
          minBet: 0.25,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Jingle Bet Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Jingle Bet Prize Drops Campaign runs for  ten (10) agreed days and takes place between 00:01 UTC on 12th December 2023 and 21st December 2023 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 20,000 EUR divided into 1000 prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            1: 150,
            2: 100,
            7: 50,
            10: 30,
            20: 20,
            60: 10,
          },
          placeAndPrizeTotal: 20000,
          prizesTotal: '2 000 EUR / 100 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Jingle Bet',
      folderPath: `${promotionsPath}/Jingle+Bet+Network+Tournament/`,
      period: ['12.12.2023 00:01 (UTC)', '21.12.2023 23:59 (UTC)'],
      description:
        'Step into the holiday spirit with our Jingle Bet Network Tournament! This December, join us for a thrilling competition that promises to add an extra dash of excitement to your festivities. Get ready to jingle and bet for a chance to win big and make this holiday season one to remember!',
      prizePool: 50000,
      market: ['Europe', 'Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.25,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'treasure_snipes_christmas',
          name: 'Treasure-snipes: Christmas',
        },
        {
          gameCode: 'christmas_reach',
          name: 'Christmas Reach',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 12th December 2023 and 21st December 2023 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 300 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 4500,
        2: 4000,
        3: 3500,
        4: 3000,
        5: 2500,
        6: 1500,
        7: 1250,
        8: 1000,
        9: 750,
        10: 500,
        '11-20': 250,
        '21-50': 200,
        '51-100': 150,
        '101-150': 100,
        '151-200': 70,
        '201-300': 30,
      },
      placeAndPrizeTotal: 50000,
    },
    {
      title: 'Festive Spins Network Campaign',
      folderPath: `${promotionsPath}/Festive+Spins+Network+Campaign/`,
      period: ['04.01.2024 00:01 (UTC)', '15.01.2024 23:59 (UTC)'],
      description:
        'Embark on a winter wonderland adventure with our Festive Spins Network Campaign! This January, experience the magic of the holiday season through our Network Tournament and Prize Drops. Join us in making the start of the year a festive celebration to remember!',
      prizePool: 100000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'treasure_snipes_christmas',
          name: 'Treasure-snipes: Christmas',
        },
        {
          gameCode: 'christmas_reach',
          name: 'Christmas Reach',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Campaign is available to play in real mode only and consist of two campaigns: Festive Spins Network Tournament and Festive Spins Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Network Campaign runs for (12) agreed days and takes place between 00:01 UTC on 4th January 2024 and 15th January 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 100,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the tournament minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Festive Spins Network Tournament',
          folderPath: `${promotionsPath}/Festive+Spins+Network+Campaign/campaigns/Festive+Spins+Network+Tournament/`,
          period: ['04.01.2024 00:01 (UTC)', '15.01.2024 23:59 (UTC)'],
          prizePool: 70000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for twelve (12) agreed days and takes place between 00:01 UTC on 4th January 2024 and 15th January 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 70,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,2 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 3150,
            3: 2800,
            4: 2450,
            5: 2100,
            6: 1960,
            7: 1890,
            8: 1750,
            9: 1610,
            10: 1330,
            '11-20': 700,
            '21-50': 287,
            '51-100': 182,
            '101-150': 140,
            '151-200': 105,
            '201-300': 70,
            '301-400': 35,
          },
          placeAndPrizeTotal: 70000,
        },
        {
          title: 'Festive Spins Prize Drops',
          folderPath: `${promotionsPath}/Festive+Spins+Network+Campaign/campaigns/Festive+Spins+Prize+Drops/`,
          period: ['04.01.2024 16:01 (UTC)', '15.01.2024 20:59 (UTC)'],
          prizePool: 30000,
          mechanic: 'Random Prize Drops',
          minBet: 0.2,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Festive Spins Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Festive Spins Prize Drops Campaign runs for  twelve (12) agreed days and takes place between 00:01 UTC on 4th January 2024 and 15th January 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 30,000 EUR divided into 2400 prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,2 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            14: 20,
            36: 15,
            60: 13,
            90: 10,
          },
          placeAndPrizeTotal: 30000,
          prizesTotal: '2 500 EUR / 200 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Festive Spins',
      folderPath: `${promotionsPath}/Festive+Spins+Network+Tournament/`,
      period: ['04.01.2024 00:01 (UTC)', '15.01.2024 23:59 (UTC)'],
      description:
        'Welcome the New Year with flair at our Festive Spins Network Tournament! In January, join us for a thrilling competition filled with holiday cheer. Get ready to spin and win, making the start of the year a festive and memorable affair!',
      prizePool: 70000,
      market: ['Europe', 'Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'treasure_snipes_christmas',
          name: 'Treasure-snipes: Christmas',
        },
        {
          gameCode: 'christmas_reach',
          name: 'Christmas Reach',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for twelve (12) agreed days and takes place between 00:01 UTC on 4th January 2024 and 15th January 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 70,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,2 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3500,
        2: 3150,
        3: 2800,
        4: 2450,
        5: 2100,
        6: 1960,
        7: 1890,
        8: 1750,
        9: 1610,
        10: 1330,
        '11-20': 700,
        '21-50': 287,
        '51-100': 182,
        '101-150': 140,
        '151-200': 105,
        '201-300': 70,
        '301-400': 35,
      },
      placeAndPrizeTotal: 70000,
    },
    {
      title: 'Midnight Countdown',
      folderPath: `${promotionsPath}/Midnight+Countdown/`,
      period: ['18.12.2023 00:01 (UTC)', '27.12.2023 23:59 (UTC)'],
      description:
        'Gear up for an exhilarating year-end celebration with our Midnight Countdown Network Tournament! Join us in a thrilling competition that will have you ringing in the New Year with style. Get ready to compete, connect, and make this December a countdown to remember!',
      prizePool: 20000,
      market: ['MGA'],
      mechanic: 'Total Win',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'mega_greatest_catch',
          name: 'Mega Greatest Catch',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'wild_overlords_bonus_buy',
          name: 'Wild Overlords Bonus Buy',
        },
        {
          gameCode: 'massive_luck',
          name: 'Massive Luck',
        },
        {
          gameCode: 'book_of_the_priestess',
          name: 'Book of the Priestess',
        },
        {
          gameCode: 'christmas_reach',
          name: 'Christmas Reach',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'wolf_hiding',
          name: 'Wolf Hiding',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 18th December 2023 and 27th December 2023 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 100 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation. <br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 150,
        '21-50': 100,
        '51-100': 60,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Solar Spin',
      folderPath: `${promotionsPath}/Solar+Spin/`,
      period: ['18.12.2023 00:01 (UTC+7)', '27.12.2023 23:59 (UTC+7)'],
      description:
        'Celebrate the winter solstice with our Solar Spin Network Tournament, dedicated to this special celestial event. Join us in a thrilling competition, and get ready to spin your way into the heart of the winter season!',
      prizePool: 35000,
      market: ['Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy ',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'patricks_magic_field',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'temple_of_thunder',
          name: 'Temple of Thunder',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'marys_mining_mania',
          name: 'Mary`s Mining Mania',
        },
        {
          gameCode: 'goblin_run',
          name: 'Goblin Run',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 +7 UTC on 18th December 2023 and 27th December 2023 until 23:59 +7 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,20 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
      placeAndPrize: {
        1: 3500,
        2: 1575,
        3: 1400,
        4: 1225,
        5: 700,
        '6-10': 560,
        '11-20': 490,
        '21-30': 350,
        '31-40': 245,
        '41-60': 175,
        '61-100': 105,
        '101-150': 70,
        '151-200': 35,
      },
      placeAndPrizeTotal: 35000,
    },
    {
      title: 'Dragon Spins',
      folderPath: `${promotionsPath}/Dragon+Spins/`,
      period: ['16.01.2024 00:01 (UTC+7)', '14.02.2024 23:59 (UTC+7)'],
      description:
        'As winter winds blend with the spirit of the forthcoming Chinese New Year, Evoplay presents the Dragon Spins Network Tournament, a celebration of fortune and thrill! Embark on an exhilarating journey with our specially themed games, offering a chance to seize from the 100,000 EUR prize pool. Embrace the holiday spirit and invite players to revel in the festivities at your casino.',
      prizePool: 100000,
      market: ['Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'temple_of_thunder',
          name: 'Temple of Thunder',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'candy_dreams_sweet_planet_b_b',
          name: 'Candy Dreams: Sweet Planet Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy ',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'goblin_run',
          name: 'Goblin Run',
        },
        {
          gameCode: 'dragons_tavern',
          name: 'Dragon`s Tavern',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament consists of 2 (two) Phases, which take place on January 16-25, February 5-14. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournaments prize pool amount is 100,000 EUR. Each of two tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Dragon+Spins/campaigns/Phase+1/`,
          period: ['16.01.2024 00:01 (UTC+7)', '25.01.2024 23:59 (UTC+7)'],
          prizePool: 50000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 3500,
            2: 2500,
            3: 2000,
            4: 1750,
            5: 1500,
            '6-10': 1200,
            '11-20': 800,
            '21-30': 500,
            '31-40': 300,
            '41-60': 200,
            '61-100': 100,
            '101-150': 75,
            '151-200': 50,
            '201-300': 25,
          },
          placeAndPrizeTotal: 50000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Dragon+Spins/campaigns/Phase+2/`,
          period: ['05.02.2024 00:01 (UTC+7)', '14.02.2024 23:59 (UTC+7)'],
          prizePool: 50000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 3500,
            2: 2500,
            3: 2000,
            4: 1750,
            5: 1500,
            '6-10': 1200,
            '11-20': 800,
            '21-30': 500,
            '31-40': 300,
            '41-60': 200,
            '61-100': 100,
            '101-150': 75,
            '151-200': 50,
            '201-300': 25,
          },
          placeAndPrizeTotal: 50000,
        },
      ],
    },
    {
      title: 'Bet on Love Network Campaign',
      folderPath: `${promotionsPath}/Bet+on+Love+Network+Campaign/`,
      period: ['06.02.2024 00:01 (UTC)', '15.02.2024 23:59 (UTC)'],
      description:
        'Unlock the mysteries of love with our unique "Bet on Love" tournament and prize drops! We will share an astounding total prize pool of 50,000 EUR! Join us in this celebration of love.',
      prizePool: 50000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          name: 'Gold of Sirens Bonus Buy',
        },
        {
          gameCode: 'money_minter_b_b',
          name: 'Money Minter Bonus Buy',
        },
        {
          gameCode: 'wild_overlords_bonus_buy',
          name: 'Wild Overlords Bonus Buy',
        },
        {
          gameCode: 'redrose_sanctuary',
          name: 'Redrose Sanctuary',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'massive_luck',
          name: 'Massive Luck',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'wolf_hiding',
          name: 'Wolf Hiding',
        },
        {
          gameCode: 'wonder_farm',
          name: 'Wonder Farm',
        },
        {
          gameCode: 'temple_of_thunder',
          name: 'Temple of Thunder',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Campaign is available to play in real mode only and consist of two campaigns: Bet on Love Network Tournament and Bet on Love Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Network Campaign runs for ten (10) agreed days and takes place between 00:01 UTC on 6th February 2024 and 15th February 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 50,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Bet on Love Network Tournament',
          folderPath: `${promotionsPath}/Bet+on+Love+Network+Campaign/campaigns/Bet+on+Love+Network+Tournament/`,
          period: ['06.02.2024 00:01 (UTC)', '15.02.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Spin',
          minBet: 0.4,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 6th February 2024 and 15th February 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Bet on Love Prize Drops',
          folderPath: `${promotionsPath}/Bet+on+Love+Network+Campaign/campaigns/Bet+on+Love+Prize+Drops/`,
          period: ['06.02.2024 00:01 (UTC)', '15.02.2024 23:59 (UTC)'],
          prizePool: 15000,
          mechanic: 'Random Prize Drops',
          minBet: 0.4,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Prize Drops Campaign runs for  ten (10) agreed days and takes place between 00:01 UTC on 6th February 2024 and 15th February 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 15,000 EUR divided into 1000 prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            1: 65,
            2: 45,
            7: 35,
            10: 20,
            20: 15,
            60: 10,
          },
          placeAndPrizeTotal: 15000,
          prizesTotal: '1 500 EUR / 100 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Bet on Love',
      folderPath: `${promotionsPath}/Bet+on+Love+Network+Tournament/`,
      period: ['06.02.2024 00:01 (UTC)', '15.02.2024 23:59 (UTC)'],
      description:
        'Welcome to our exciting "Bet on Love" tournament with 35,000 EUR in prize pool! Immerse yourself in the atmosphere of love by playing our most favorite games.',
      prizePool: 35000,
      market: ['Europe', 'Asia'],
      mechanic: 'Total Spin',
      minBet: 0.4,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          name: 'Gold of Sirens Bonus Buy',
        },
        {
          gameCode: 'money_minter_b_b',
          name: 'Money Minter Bonus Buy',
        },
        {
          gameCode: 'wild_overlords_bonus_buy',
          name: 'Wild Overlords Bonus Buy',
        },
        {
          gameCode: 'redrose_sanctuary',
          name: 'Redrose Sanctuary',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'massive_luck',
          name: 'Massive Luck',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'wolf_hiding',
          name: 'Wolf Hiding',
        },
        {
          gameCode: 'wonder_farm',
          name: 'Wonder Farm',
        },
        {
          gameCode: 'temple_of_thunder',
          name: 'Temple of Thunder',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 6th February 2024 and 15th February 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3500,
        2: 2800,
        3: 2450,
        4: 2100,
        5: 1750,
        6: 1400,
        7: 1155,
        8: 980,
        9: 910,
        10: 455,
        '11-20': 280,
        '21-50': 140,
        '51-100': 105,
        '101-150': 70,
        '151-200': 35,
      },
      placeAndPrizeTotal: 35000,
    },
    {
      title: 'Spin in Love',
      folderPath: `${promotionsPath}/Spin+in+Love+Network+Tournament/`,
      period: ['09.02.2024 00:01 (UTC)', '18.02.2024 23:59 (UTC)'],
      description:
        'Get ready to spin into romance with our exclusive "Spin in Love" tournament! With captivating games and thrilling prizes awaiting, this event promises unforgettable thrills.',
      prizePool: 20000,
      market: ['MGA'],
      mechanic: 'Total Spin',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          name: 'Gold of Sirens Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'wild_overlords_bonus_buy',
          name: 'Wild Overlords Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'money_minter_b_b',
          name: 'Money Minter Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'massive_luck',
          name: 'Massive Luck',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'wolf_hiding',
          name: 'Wolf Hiding',
        },
        {
          gameCode: 'wonder_farm',
          name: 'Wonder Farm',
        },
        {
          gameCode: 'temple_of_thunder',
          name: 'Temple of Thunder',
        },
        {
          gameCode: 'redrose_sanctuary',
          name: 'Redrose Sanctuary',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 9th February 2024 and 18th February 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 100 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 150,
        '21-50': 100,
        '51-100': 60,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Cash Flash Network Campaign',
      folderPath: `${promotionsPath}/Cash+Flash+Network+Campaign/`,
      period: ['08.03.2024 00:01 (UTC)', '17.03.2024 23:59 (UTC)'],
      description:
        'March is about to get even more exhilarating with Evoplay&#39;s Cash Flash Network Tournament + Prize Drops! With a sensational prize pool of 50,000 EUR plus 10,000 Free Spins up for grabs, players will be captivated by the excitement of winning big. Elevate your casino experience and engage players like never before by adding this dynamic duo to your March schedule!',
      prizePool: 65000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'goddess_of_the_night',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
        {
          gameCode: 'hockey_shootout',
          name: 'Hockey Shootout',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Campaign is available to play in real mode only and consist of two campaigns: Cash Flash Network Tournament and Cash Flash Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Network Campaign runs for ten (10) agreed days and takes place between 00:01 UTC on 8th March 2024 and 17th March 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 60,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Cash Flash Network Tournament',
          folderPath: `${promotionsPath}/Cash+Flash+Network+Campaign/campaigns/Cash+Flash+Network+Tournament/`,
          period: ['08.03.2024 00:01 (UTC)', '17.03.2024 23:59 (UTC)'],
          prizePool: 40000,
          mechanic: 'Sum Multiplier',
          minBet: 0.25,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 8th March 2024 and 17th March 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 700 players who have the highest scores on the leaderboards. 35,000 EUR will be distributed among 200 cash prizes and 5,000 EUR will be distributed among Free Rounds.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
            '201-700': '10 free spins* / 1 EUR each',
          },
          placeAndPrizeTotal: 40000,
          prizesTotal:
            '*free spins will be awarded to the player at the end of the tournament in the Hockey Shootout game',
        },
        {
          title: 'Cash Flash Prize Drops',
          folderPath: `${promotionsPath}/Cash+Flash+Network+Campaign/campaigns/Cash+Flash+Prize+Drops/`,
          period: ['08.03.2024 00:01 (UTC)', '17.03.2024 23:59 (UTC)'],
          prizePool: 25000,
          mechanic: 'Random Prize Drops',
          minBet: 0.25,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Prize Drops Campaign runs for  ten (10) agreed days and takes place between 00:01 UTC on 8th March 2024 and 17th March 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 25,000 EUR divided into 2000 prizes. 20,000 EUR will be distributed among 1000 cash prizes and 5,000 EUR will be distributed among Free Rounds. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            1: 150,
            2: 100,
            7: 50,
            10: 30,
            20: 20,
            60: 10,
            100: '5 Free Rounds / 1 EUR each',
          },
          placeAndPrizeTotal: 25000,
          prizesTotal: '2500 EUR / 200 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Cash Flash',
      folderPath: `${promotionsPath}/Cash+Flash+Network+Tournament/`,
      period: ['08.03.2024 00:01 (UTC)', '17.03.2024 23:59 (UTC)'],
      description:
        'As March rolls in, get ready to ignite excitement in your casino with Evoplay&#39;s Cash Flash Network Tournament! With a whopping prize pool of 35,000 EUR plus 5000 Free Spins, players will be treated to an electrifying gaming experience like no other. Don&#39;t miss out on the chance to add this thrilling event to your casino lineup!',
      prizePool: 40000,
      market: ['Europe', 'Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.25,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'goddess_of_the_night',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
        {
          gameCode: 'hockey_shootout',
          name: 'Hockey Shootout',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 8th March 2024 and 17th March 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 700 players who have the highest scores on the leaderboards. 35,000 EUR will be distributed among 200 cash prizes and 5,000 EUR will be distributed among Free Rounds.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3500,
        2: 2800,
        3: 2450,
        4: 2100,
        5: 1750,
        6: 1400,
        7: 1155,
        8: 980,
        9: 910,
        10: 455,
        '11-20': 280,
        '21-50': 140,
        '51-100': 105,
        '101-150': 70,
        '151-200': 35,
        '201-700': '10 free spins* / 1 EUR each',
      },
      placeAndPrizeTotal: 40000,
      prizesTotal:
        '*free spins will be awarded to the player at the end of the tournament in the Hockey Shootout game',
    },
    {
      title: 'Golden Fortune',
      folderPath: `${promotionsPath}/Golden+Fortune/`,
      period: ['19.03.2024 00:01 (UTC+7)', '28.03.2024 23:59 (UTC+7)'],
      description:
        'Get ready to strike gold with the Golden Fortune Network Tournament in March! This thrilling event offers a prize pool of 20,000 EUR, giving your players the chance to win big while experiencing the excitement of our top games. Don&#39;t miss out on this golden opportunity to elevate your casino to new heights of success!',
      prizePool: 20000,
      market: ['Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.25,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'legacy_of_the_sages',
          name: 'Legacy of the Sages',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-Out Street',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'camino_de_chili',
          name: 'Camino de Chili',
        },
        {
          gameCode: 'money_minter',
          name: 'Money Minter',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'sold_it_b_b',
          name: 'Sold it Bonus Buy',
        },
        {
          gameCode: 'europe_transit_b_b',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 +7 UTC on 19th March 2024 and 28th March 2024 until 23:59 +7 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.',
      placeAndPrize: {
        1: 2000,
        2: 1500,
        3: 1200,
        4: 1000,
        5: 700,
        '6-10': 500,
        '11-20': 300,
        '21-30': 200,
        '31-40': 100,
        '41-60': 80,
        '61-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Spring Riches',
      folderPath: `${promotionsPath}/Spring+Riches+Network+Tournament/`,
      period: ['09.04.2024 00:01 (UTC)', '18.04.2024 23:59 (UTC)'],
      description:
        'Step into the world of Spring Riches with our exclusive Network Tournament this April! Dive into the thrill of competition and seize your chance to win from a generous prize pool of 20,000 EUR. Join us for an exciting gaming experience filled with surprises and rewards. Don&#39;t miss out &ndash; select Spring Riches Network Tournament now and start your journey to riches!',
      prizePool: 20000,
      market: ['MGA'],
      mechanic: 'Sum Multiplier',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
        {
          gameCode: 'wolf_hiding',
          name: 'Wolf Hiding',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'wild_overlords_bonus_buy',
          name: 'Wild Overlords Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess_b_b',
          name: 'Book Of The Priestess Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          name: 'Gold of Sirens Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Network Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 9th April 2024 and 18th April 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 100 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 150,
        '21-50': 100,
        '51-100': 60,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Fortune Wings Campaign',
      folderPath: `${promotionsPath}/Fortune+Wings+Campaign/`,
      period: ['19.04.2024 00:01 (UTC)', '28.04.2024 23:59 (UTC)'],
      description:
        'Elevate the excitement in your casino this April with Evoplay&#39;s Fortune Wings Tournament + Prize Drops! With an astounding prize pool of 50,000 EUR plus 10,000 Free Spins, players will be immersed in a world of endless winning opportunities. Enhance player engagement and take your casino to new heights by adding this dynamic duo to your April lineup!',
      prizePool: 60000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'the_belt_of_champion',
          name: 'The Belt of the Champion',
        },
        {
          gameCode: 'hockey_shootout',
          name: 'Hockey Shootout',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two campaigns: Fortune Wings Tournament and Fortune Wings Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Campaign runs for ten (10) agreed days and takes place between 00:01 UTC on 19th April 2024 and 28th April 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 60,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Fortune Wings Tournament',
          folderPath: `${promotionsPath}/Fortune+Wings+Campaign/campaigns/Fortune+Wings+Tournament/`,
          period: ['19.04.2024 00:01 (UTC)', '28.04.2024 23:59 (UTC)'],
          prizePool: 40000,
          mechanic: 'Total Win',
          minBet: 0.25,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 19th April 2024 and 28th April 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 700 players who have the highest scores on the leaderboards. 35,000 EUR will be distributed among 200 cash prizes and 5,000 EUR will be distributed among Free Rounds.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
            '201-700': '10 free spins* / 1 EUR each',
          },
          placeAndPrizeTotal: 40000,
          prizesTotal:
            '*free spins will be awarded to the player at the end of the tournament in the Penalty Shoot-out Street game',
        },
        {
          title: 'Fortune Wings Prize Drops',
          folderPath: `${promotionsPath}/Fortune+Wings+Campaign/campaigns/Fortune+Wings+Prize+Drops/`,
          period: ['19.04.2024 00:01 (UTC)', '28.04.2024 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Random Prize Drops',
          minBet: 0.25,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Prize Drops Campaign runs for  ten (10) agreed days and takes place between 00:01 UTC on 19th April 2024 and 28th April 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 20,000 EUR divided into 2000 prizes. 15,000 EUR will be distributed among 1000 cash prizes and 5,000 EUR will be distributed among Free Rounds. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            1: 65,
            2: 45,
            7: 35,
            10: 20,
            20: 15,
            60: 10,
            100: '5 Free Rounds / 1 EUR each',
          },
          placeAndPrizeTotal: 20000,
          prizesTotal: '2000 EUR / 200 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Fortune Wings',
      folderPath: `${promotionsPath}/Fortune+Wings+Tournament/`,
      period: ['19.04.2024 00:01 (UTC)', '28.04.2024 23:59 (UTC)'],
      description:
        'Prepare for an unforgettable gaming adventure with Evoplay&#39;s Fortune Wings Tournament, soaring into action this April! Featuring an impressive prize pool of 35,000 EUR plus 5000 Free Spins, players will embark on a thrilling journey to fortune. Don&#39;t miss out on the opportunity to elevate your casino&#39;s excitement!',
      prizePool: 40000,
      market: ['Europe', 'Asia'],
      mechanic: 'Total Win',
      minBet: 0.25,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'the_belt_of_champion',
          name: 'The Belt of the Champion',
        },
        {
          gameCode: 'hockey_shootout',
          name: 'Hockey Shootout',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 19th April 2024 and 28th April 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 700 players who have the highest scores on the leaderboards. 35,000 EUR will be distributed among 200 cash prizes and 5,000 EUR will be distributed among Free Rounds.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,25 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are covered from GGR before invoicing. All free spins values are covered from GGR by Evoplay. All winning from free rounds are counted in the total GGR and don&#39;t have additional coverage by provider.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3500,
        2: 2800,
        3: 2450,
        4: 2100,
        5: 1750,
        6: 1400,
        7: 1155,
        8: 980,
        9: 910,
        10: 455,
        '11-20': 280,
        '21-50': 140,
        '51-100': 105,
        '101-150': 70,
        '151-200': 35,
        '201-700': '10 free spins* / 1 EUR each',
      },
      placeAndPrizeTotal: 40000,
      prizesTotal:
        '*free spins will be awarded to the player at the end of the tournament in the Penalty Shoot-out Street game',
    },
    {
      title: 'Lucky Coins',
      folderPath: `${promotionsPath}/Lucky+Coins/`,
      period: ['12.04.2024 00:01 (UTC+7)', '21.05.2024 23:59 (UTC+7)'],
      description:
        'Welcome to the Lucky Coins Tournament, a thrilling gaming event taking place throughout April and May! With a total prize pool of 40,000 EUR up for grabs, this tournament is your ticket to an exciting journey filled with luck and fortune. Join us as we embark on this adventure together and let the coins guide you to victory!',
      prizePool: 40000,
      market: ['Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.25,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy ',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'dragons_tavern',
          name: 'Dragon`s Tavern',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'book_of_the_priestess',
          name: 'Book of the Priestess',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament consists of 2 (two) Phases, which take place on April 12-21, May 10-19. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournaments prize pool amount is 40,000 EUR. Each of two tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,25 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. Each of the tournaments has its list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Lucky+Coins/campaigns/Phase+1/`,
          period: ['12.04.2024 00:01 (UTC+7)', '21.04.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Sum Multiplier',
          minBet: 0.25,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Lucky+Coins/campaigns/Phase+2/`,
          period: ['10.05.2024 00:01 (UTC+7)', '19.05.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Sum Multiplier',
          minBet: 0.25,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
      ],
    },
    {
      title: 'Mai Tai Madness Campaign',
      folderPath: `${promotionsPath}/Mai+Tai+Madness+Campaign/`,
      period: ['15.05.2024 00:01 (UTC)', '28.05.2024 23:59 (UTC)'],
      description:
        'Extend the excitement and double the rewards with our Mai Tai Madness Tournament + Prize Drops! From May 15th to 28th, dive into the ultimate gaming adventure and compete for your share of the massive 70,000 EUR prize pool. With the added thrill of Prize Drops, it&#39;s time to elevate your gaming experience and make the most of Mai Tai Madness!',
      prizePool: 70000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'patricks_magic_field',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'inner_fire',
          name: 'Inner Fire',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'europe_transit_b_b',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'lucky_porker_b_b',
          name: 'Lucky Porker Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'neon_capital_b_b',
          name: 'Neon Capital Bonus Buy',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'thunder_mega_sevens',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'goddess_of_the_night',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'catch_the_wind',
          name: 'Catch the Wind',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two campaigns: Mai Tai Madness Tournament and Mai Tai Madness Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Campaign runs for fourteen (14) agreed days and takes place between 00:01 UTC on 15th May 2024 and 28th May 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 70,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Mai Tai Madness Tournament',
          folderPath: `${promotionsPath}/Mai+Tai+Madness+Campaign/campaigns/Mai+Tai+Madness+Tournament/`,
          period: ['15.05.2024 00:01 (UTC)', '21.05.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Spin',
          minBet: 0.4,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 15th May 2024 and 21st May 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,40 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Mai Tai Madness Prize Drops',
          folderPath: `${promotionsPath}/Mai+Tai+Madness+Campaign/campaigns/Mai+Tai+Madness+Prize+Drops/`,
          period: ['22.05.2024 00:01 (UTC)', '28.05.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Random Prize Drops',
          minBet: 0.4,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Prize Drops Campaign runs for seven (7) agreed days and takes place between 00:01 UTC on 22nd May 2024 and 28th May 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 35,000 EUR divided into 1400 prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,40 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            1: 400,
            2: 200,
            7: 100,
            10: 80,
            20: 40,
            60: 15,
            100: 10,
          },
          placeAndPrizeTotal: 35000,
          prizesTotal: '5000 EUR / 200 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Mai Tai Madness',
      folderPath: `${promotionsPath}/Mai+Tai+Madness+Tournament/`,
      period: ['15.05.2024 00:01 (UTC)', '21.05.2024 23:59 (UTC)'],
      description:
        'Get ready to indulge in Mai Tai Madness this May with our thrilling tournament! From May 15th to 21st, join us for an unforgettable gaming experience packed with excitement and the chance to win from our impressive 35,000 EUR prize pool. Don&#39;t miss out on the fun - mark your calendars and join the madness!',
      prizePool: 35000,
      market: ['Europe', 'Asia'],
      mechanic: 'Total Spin',
      minBet: 0.4,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'patricks_magic_field',
          name: "Patrick's Magic Field",
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'fruit_nova',
          name: 'Fruit Nova',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'inner_fire',
          name: 'Inner Fire',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'europe_transit_b_b',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'lucky_porker_b_b',
          name: 'Lucky Porker Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'neon_capital_b_b',
          name: 'Neon Capital Bonus Buy',
        },
        {
          gameCode: 'treasure_snipes',
          name: 'Treasure-snipes',
        },
        {
          gameCode: 'thunder_mega_sevens',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'goddess_of_the_night',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'catch_the_wind',
          name: 'Catch the Wind',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 15th May 2024 and 21st May 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,40 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3500,
        2: 2800,
        3: 2450,
        4: 2100,
        5: 1750,
        6: 1400,
        7: 1155,
        8: 980,
        9: 910,
        10: 455,
        '11-20': 280,
        '21-50': 140,
        '51-100': 105,
        '101-150': 70,
        '151-200': 35,
      },
      placeAndPrizeTotal: 35000,
    },
    {
      title: 'Football Rush',
      folderPath: `${promotionsPath}/Football+Rush/`,
      period: ['13.06.2024 00:01 (UTC)', '14.07.2024 23:59 (UTC)'],
      description:
        'Prepare to dive into the excitement of the UEFA European Football Championship with our Football Rush Tournament! From June 13th to July 14th, immerse yourself in the thrill of the game as we celebrate one of the most prestigious football tournaments in Europe. With four action-packed phases, each spanning eight days, and an impressive prize pool of 200,000 EUR, it&#39;s time to take to the pitch and compete for glory in the Football Rush Tournament!',
      prizePool: 200000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'penalty_series',
          name: 'Penalty Series',
        },
        {
          gameCode: 'long_ball',
          name: 'Long Ball',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'goddess_of_the_night',
          name: 'Goddess of the Night',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'northern_temple_b_b',
          name: 'Northern Temple Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Tournament runs for (32) agreed days and takes place between 00:01 UTC on 13th June 2024 and 14th July 2024 until 23:59 UTC. Tournament consists of 4 phases, each phase runs for eight (8) agreed days.<br/><br/>' +
        '3. The total tournament prize amount is 200,000 EUR and will be distributed among the top 1200 players who have the highest scores on the leaderboards. Each phase has its own prize pool and its own prize pool distribution table.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. Each phase has its own its qualified mechanic.<br/><br/>' +
        '10. Every qualified spin, win or win multiplier (according to the tournament mechanic) contributes to the player&#39;s total score, shown on the leaderboard. The total score is calculated by adding all points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '11. If several participants gain the same number of points, the participant who scores first is given a higher position on the leaderboard.<br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes (If you have autopayments connected, prizes will be credited automatically).<br/><br/>' +
        '13. The prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of the promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Football Rush: Group Stage I',
          folderPath: `${promotionsPath}/Football+Rush/campaigns/Group+Stage+I/`,
          period: ['13.06.2024 00:01 (UTC)', '20.06.2024 23:59 (UTC)'],
          prizePool: 45000,
          mechanic: 'Sum Multiplier',
          minBet: 0.3,
          tags: ['Network'],
          placeAndPrize: {
            1: 4500,
            2: 2925,
            3: 2475,
            4: 2025,
            5: 1800,
            6: 1530,
            7: 1215,
            8: 900,
            9: 630,
            10: 450,
            '11-20': 270,
            '21-40': 225,
            '41-70': 180,
            '71-100': 135,
            '101-170': 90,
            '171-250': 45,
          },
          placeAndPrizeTotal: 45000,
        },
        {
          title: 'Football Rush: Group Stage II',
          folderPath: `${promotionsPath}/Football+Rush/campaigns/Group+Stage+II/`,
          period: ['21.06.2024 00:01 (UTC)', '28.06.2024 23:59 (UTC)'],
          prizePool: 45000,
          mechanic: 'Sum Multiplier',
          minBet: 0.3,
          tags: ['Network'],
          placeAndPrize: {
            1: 4500,
            2: 2925,
            3: 2475,
            4: 2025,
            5: 1800,
            6: 1530,
            7: 1215,
            8: 900,
            9: 630,
            10: 450,
            '11-20': 270,
            '21-40': 225,
            '41-70': 180,
            '71-100': 135,
            '101-170': 90,
            '171-250': 45,
          },
          placeAndPrizeTotal: 45000,
        },
        {
          title: 'Football Rush: Quarter - final',
          folderPath: `${promotionsPath}/Football+Rush/campaigns/Quarter+final/`,
          period: ['29.06.2024 00:01 (UTC)', '06.07.2024 23:59 (UTC)'],
          prizePool: 50000,
          mechanic: 'Total Spin',
          minBet: 0.3,
          tags: ['Network'],
          placeAndPrize: {
            1: 4500,
            2: 3500,
            3: 3000,
            4: 2500,
            5: 2000,
            6: 1500,
            7: 1250,
            8: 1000,
            9: 750,
            10: 500,
            '11-20': 250,
            '21-50': 200,
            '51-100': 150,
            '101-150': 100,
            '151-200': 70,
            '201-300': 50,
          },
          placeAndPrizeTotal: 50000,
        },
        {
          title: 'Football Rush: Final',
          folderPath: `${promotionsPath}/Football+Rush/campaigns/Final/`,
          period: ['07.07.2024 00:01 (UTC)', '14.07.2024 23:59 (UTC)'],
          prizePool: 60000,
          mechanic: 'Total Win',
          minBet: 0.3,
          tags: ['Network'],
          placeAndPrize: {
            1: 4800,
            2: 3000,
            3: 2400,
            4: 2100,
            5: 1860,
            6: 1740,
            7: 1500,
            8: 1200,
            9: 900,
            10: 600,
            '11-20': 480,
            '21-50': 270,
            '51-100': 150,
            '101-150': 120,
            '151-200': 90,
            '201-300': 60,
            '301-400': 30,
          },
          placeAndPrizeTotal: 60000,
        },
      ],
    },
    {
      title: 'Copa Craze',
      folderPath: `${promotionsPath}/Copa+Craze/`,
      period: ['20.06.2024 00:01 (UTC)', '17.07.2024 23:59 (UTC)'],
      description:
        'Get ready to experience the ultimate football fever with our Copa Craze Tournament! Dedicated to the exciting Copa America championship, this tournament will run from June 20th to July 17th. With four intense phases, each lasting a week, and an incredible prize pool of 60,000 EUR, it&#39;s time to show off your skills and score big in the Copa Craze Tournament!',
      prizePool: 60000,
      market: ['Latin America'],
      languages: ['en', 'es', 'pt'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'penalty_series',
          name: 'Penalty Series',
        },
        {
          gameCode: 'long_ball',
          name: 'Long Ball',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
        {
          gameCode: 'juicy_gems',
          name: 'Juicy Gems',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Tournament consists of 4 (four) Phases, which take place on June 20 - 26, June 27 - July 3, July 4 - 10, July 11 - 17. The Phases start at 00:01 UTC -5 and finish at 23:59 UTC -5 on the corresponding dates.<br/><br/>' +
        '3. The total tournament prize pool amount is 60,000 EUR. Each of four tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,2 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract in the corresponding month in 2024.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. If the player&#39;s currency is different from the established exchange rate (BRL, ARS, PEN, VES, SLP) the conversion rate locked at the beginning of the Tournament will be used instead.<br/><br/>' +
        '9. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '10. Each of four tournaments has its qualified mechanic.<br/><br/>' +
        '11. Every qualified spin, win or win multiplier (according to the tournament mechanic) contributes to the player&#39;s total score, shown on the leaderboard. The total score is calculated by adding all points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '12. If several participants gain the same number of points, the participant who scores first is given a higher position on the leaderboard.<br/><br/>' +
        '13. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes (If you have autopayments connected, prizes will be credited automatically).<br/><br/>' +
        '14. The prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of the promotion.<br/><br/>' +
        '15. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '16. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '17. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '18. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '19. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '20. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Group Phase I',
          folderPath: `${promotionsPath}/Copa+Craze/campaigns/Group+Phase+I/`,
          period: ['20.06.2024 00:01 (UTC)', '26.06.2024 23:59 (UTC)'],
          prizePool: 15000,
          mechanic: 'Sum Multiplier',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 1500,
            2: 1200,
            3: 900,
            4: 750,
            5: 450,
            '6-10': 300,
            '11-20': 180,
            '21-30': 150,
            '31-40': 90,
            '41-60': 60,
            '61-100': 45,
            '101-150': 30,
          },
          placeAndPrizeTotal: 15000,
        },
        {
          title: 'Group Phase II',
          folderPath: `${promotionsPath}/Copa+Craze/campaigns/Group+Phase+II/`,
          period: ['27.06.2024 00:01 (UTC)', '03.07.2024 23:59 (UTC)'],
          prizePool: 15000,
          mechanic: 'Sum Multiplier',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 1500,
            2: 1200,
            3: 900,
            4: 750,
            5: 450,
            '6-10': 300,
            '11-20': 180,
            '21-30': 150,
            '31-40': 90,
            '41-60': 60,
            '61-100': 45,
            '101-150': 30,
          },
          placeAndPrizeTotal: 15000,
        },
        {
          title: 'Semi - final',
          folderPath: `${promotionsPath}/Copa+Craze/campaigns/Semi+final/`,
          period: ['04.07.2024 00:01 (UTC)', '10.07.2024 23:59 (UTC)'],
          prizePool: 15000,
          mechanic: 'Total Spin',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 1500,
            2: 1200,
            3: 900,
            4: 750,
            5: 450,
            '6-10': 300,
            '11-20': 180,
            '21-30': 150,
            '31-40': 90,
            '41-60': 60,
            '61-100': 45,
            '101-150': 30,
          },
          placeAndPrizeTotal: 15000,
        },
        {
          title: 'Final',
          folderPath: `${promotionsPath}/Copa+Craze/campaigns/Final/`,
          period: ['11.07.2024 00:01 (UTC)', '17.07.2024 23:59 (UTC)'],
          prizePool: 15000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 1500,
            2: 1200,
            3: 900,
            4: 750,
            5: 450,
            '6-10': 300,
            '11-20': 180,
            '21-30': 150,
            '31-40': 90,
            '41-60': 60,
            '61-100': 45,
            '101-150': 30,
          },
          placeAndPrizeTotal: 15000,
        },
      ],
    },
    {
      title: 'Golden Paradise',
      folderPath: `${promotionsPath}/Golden+Paradise/`,
      period: ['18.06.2024 00:01 (UTC+7)', '25.08.2024 23:59 (UTC+7)'],
      description:
        'Welcome to the Golden Paradise Tournament! From June to August, immerse yourself in an oasis of gaming excellence. With a dazzling prize pool of 60,000 EUR awaiting discovery, this tournament promises an unforgettable journey to the heart of Golden Paradise. Join us and seize the opportunity to bask in riches beyond compare. Don&#39;t miss your chance to experience the magic of Golden Paradise!',
      prizePool: 60000,
      market: ['Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'legacy_of_the_sages',
          name: 'Legacy of the Sages',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Tournament consists of 3 (three) Phases, which take place on June 18-27, July 19-28, August 16-25. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournament prize pool amount is 60,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract in the corresponding month in 2024.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. If the player&#39;s currency is different from the established exchange rate (EUR, CNY, JPY, KRW, THB, VND, IDR) the conversion rate locked at the beginning of the Tournament will be used instead.<br/><br/>' +
        '9. Each of the tournaments has its list of qualified games and a prize pool, which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '10. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '11. Every qualified spin contributes to the player&#39;s total score, shown on the leaderboard. The total score is calculated by adding the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '12. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '13. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '14. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of the promotion.<br/><br/>' +
        '15. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '16. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '17. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '18. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '19. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '20. Submit your participation in the tournament minimum three (3) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Golden+Paradise/campaigns/Phase+1/`,
          period: ['18.06.2024 00:01 (UTC+7)', '27.06.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Golden+Paradise/campaigns/Phase+2/`,
          period: ['19.07.2024 00:01 (UTC+7)', '28.07.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 3',
          folderPath: `${promotionsPath}/Golden+Paradise/campaigns/Phase+3/`,
          period: ['16.08.2024 00:01 (UTC+7)', '25.08.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
      ],
    },
    {
      title: 'Treasures Hunting',
      folderPath: `${promotionsPath}/Treasures+Hunting/`,
      period: ['16.07.2024 00:01 (UTC)', '25.07.2024 23:59 (UTC)'],
      description:
        'Embark on an adventure like no other with the Treasures Hunting Tournament! This July, dive deep into the excitement and compete for a share of the grand 30,000 EUR prize pool. Unearth hidden riches and claim your fortune in this thrilling quest. Join the Treasures Hunting Tournament and let the search for gold begin!',
      prizePool: 30000,
      market: ['MGA'],
      mechanic: 'Sum Multiplier',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess_b_b',
          name: 'Book Of The Priestess Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder',
          name: 'Temple of Thunder',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens',
          name: 'Thunder Mega Sevens',
        },
        {
          gameCode: 'goddess_of_the_night_b_b',
          name: 'Goddess of the Night Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 16th July 2024 and 25th July 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 30,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 2100,
        4: 1800,
        5: 1650,
        6: 1500,
        7: 1200,
        8: 1050,
        9: 900,
        10: 600,
        '11-20': 270,
        '21-50': 120,
        '51-100': 90,
        '101-150': 60,
      },
      placeAndPrizeTotal: 30000,
    },
    {
      title: 'Evoplay Super Cup Campaign',
      folderPath: `${promotionsPath}/Evoplay+Super+Cup+Campaign/`,
      period: ['06.08.2024 00:01 (UTC)', '15.08.2024 23:59 (UTC)'],
      description:
        'Get ready for the ultimate gaming experience in August with the Evoplay Super Cup Tournament + Wheel of Fortune! Featuring a total prize pool of 110,000 EUR with 60,000 EUR dedicated to the tournament and 50,000 EUR for the Wheel of Fortune, this event is set to thrill. Engage your players with unmatched excitement and the chance to win big. Make your casino the go-to destination for this spectacular event and elevate the gaming experience to new heights. Don&#39;t miss out on the fun and fortune!',
      prizePool: 110000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Wheel'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'ocean_catch',
          name: 'Ocean Catch',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two campaigns: Evoplay Super Cup Tournament and Evoplay Super Cup Wheel of Fortune Campaign.<br/><br/>' +
        '2. Evoplay Campaign runs for fourteen (10) agreed days and takes place between 00:01 UTC on 6thAugust 2024 and 15th August 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 110,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Evoplay Super Cup Tournament',
          folderPath: `${promotionsPath}/Evoplay+Super+Cup+Campaign/campaigns/Evoplay+Super+Cup+Tournament/`,
          period: ['06.08.2024 00:01 (UTC)', '15.08.2024 23:59 (UTC)'],
          prizePool: 60000,
          mechanic: 'Sum Multiplier',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (10) agreed days and takes place between 00:01 UTC on 6thAugust 2024 and 15th August 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 60,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 4800,
            2: 3000,
            3: 2400,
            4: 2100,
            5: 1860,
            6: 1740,
            7: 1500,
            8: 1200,
            9: 900,
            10: 600,
            '11-20': 480,
            '21-50': 270,
            '51-100': 150,
            '101-150': 120,
            '151-200': 90,
            '201-300': 60,
            '301-400': 30,
          },
          placeAndPrizeTotal: 60000,
        },
        {
          title: 'Evoplay Super Cup Wheel of Fortune',
          folderPath: `${promotionsPath}/Evoplay+Super+Cup+Campaign/campaigns/Evoplay+Super+Cup+Wheel+of+Fortune/`,
          period: ['06.08.2024 00:01 (UTC)', '15.08.2024 23:59 (UTC)'],
          prizePool: 50000,
          mechanic: 'Wheel of Fortune',
          minBet: 0.3,
          tags: ['Wheel'],
          termsAndConditions:
            '1. Evoplay Wheel of Fortune is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Wheel of Fortune runs for  seven (10) agreed days and takes place between 00:01 UTC on 6th August 2024 and 15th August 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 50,000 EUR divided into 3630 prizes. Every qualified spin can trigger a wheel of fortune. <br/><br/>' +
            '4. The prizes in Wheel of fortune will be randomly distributed during the campaign. <br/><br/>' +
            '5. The leaderboard is consolidated for all operators participating in this Wheel of Fortune.<br/><br/>' +
            '6. The minimum bet required to participate in the Wheel of Fortune Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '7. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '8. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '9. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '10. The Wheel of Fortune has a list of qualified games and a prize pool (EUR) distributed among the winners according to the campaign rules.The Wheel of Fortune has its own mechanic of winning prizes at any time during the event period. The Wheel of Fortune is granted randomly, and all players have the same chance of winning wheel in every qualifying spin. Half of the sectors on the Wheel of Fortune yield winnings, while the remaining half contain zero - the player&#39;s chance of winning is 50%.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Wheel of Fortune Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Wheel of Fortune Campaign two (2) days before the Wheel of Fortune Campaign.<br/><br/>' +
            '17. Submit your participation in the Wheel of Fortune Campaign minimum seven (7) days before the start of the Wheel of Fortune Campaign.',
          placeAndPrize: {
            3: 100,
            5: 50,
            15: 30,
            30: 20,
            60: 15,
            250: 10,
          },
          placeAndPrizeTotal: 50000,
          prizesTotal: '5000 EUR / 363 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Evoplay Super Cup',
      folderPath: `${promotionsPath}/Evoplay+Super+Cup+Tournament/`,
      period: ['06.08.2024 00:01 (UTC)', '15.08.2024 23:59 (UTC)'],
      description:
        'August brings a grand challenge - make your casino the epicenter of excitement with the Evoplay Super Cup Tournament. With a staggering prize pool of 60,000 EUR the stakes have never been higher. Engage your players with top-tier gameplay and electrifying competition. Add this exhilarating event to your casino and watch as the thrill of the Super Cup captivates your audience. Let the games begin and may the best player win! ',
      prizePool: 60000,
      market: ['Europe', 'Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.3,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'ocean_catch',
          name: 'Ocean Catch',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for seven (10) agreed days and takes place between 00:01 UTC on 6thAugust 2024 and 15th August 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 60,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 4800,
        2: 3000,
        3: 2400,
        4: 2100,
        5: 1860,
        6: 1740,
        7: 1500,
        8: 1200,
        9: 900,
        10: 600,
        '11-20': 480,
        '21-50': 270,
        '51-100': 150,
        '101-150': 120,
        '151-200': 90,
        '201-300': 60,
        '301-400': 30,
      },
      placeAndPrizeTotal: 60000,
    },
    {
      title: 'Pina Colada Paradise Campaign',
      folderPath: `${promotionsPath}/Pina+Colada+Paradise+Campaign/`,
      period: ['06.09.2024 00:01 (UTC)', '15.09.2024 23:59 (UTC)'],
      description:
        'Get ready for a refreshing wave of excitement this September with the Pina Colada Paradise Tournament + Wheel of Fortune! Dive into a tropical escape where your players can compete for a sizzling prize pool of 110,000 EUR: 60,000 EUR in the tournament and an additional 50,000 EUR in the Wheel of Fortune. It &#39;s the perfect blend of thrilling competition and delightful surprises, making your casino the ultimate destination for end-of-summer fun.',
      prizePool: 110000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Wheel'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two campaigns: Pina Colada Paradise Tournament and Pina Colada Paradise Wheel of Fortune Campaign.<br/><br/>' +
        '2. Evoplay Campaign runs for  ten (10) agreed days and takes place between 00:01 UTC on 6th September 2024 and 15th September 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 110,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Pina Colada Paradise Tournament',
          folderPath: `${promotionsPath}/Pina+Colada+Paradise+Campaign/campaigns/Pina+Colada+Paradise+Tournament/`,
          period: ['06.09.2024 00:01 (UTC)', '15.09.2024 23:59 (UTC)'],
          prizePool: 60000,
          mechanic: 'Total Win',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 6th September 2024 and 15th September 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 60,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 4800,
            2: 3000,
            3: 2400,
            4: 2100,
            5: 1860,
            6: 1740,
            7: 1500,
            8: 1200,
            9: 900,
            10: 600,
            '11-20': 480,
            '21-50': 270,
            '51-100': 150,
            '101-150': 120,
            '151-200': 90,
            '201-300': 60,
            '301-400': 30,
          },
          placeAndPrizeTotal: 60000,
        },
        {
          title: 'Pina Colada Paradise Wheel of Fortune',
          folderPath: `${promotionsPath}/Pina+Colada+Paradise+Campaign/campaigns/Pina+Colada+Paradise+Wheel+of+Fortune/`,
          period: ['06.09.2024 00:01 (UTC)', '15.09.2024 23:59 (UTC)'],
          prizePool: 50000,
          mechanic: 'Wheel of Fortune',
          minBet: 0.3,
          tags: ['Wheel'],
          termsAndConditions:
            '1. Evoplay Wheel of Fortune is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Wheel of Fortune runs for  ten (10) agreed days and takes place between 00:01 UTC on 6th September 2024 and 15th September 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 50,000 EUR divided into 3630 prizes. Every qualified spin can trigger a wheel of fortune. <br/><br/>' +
            '4. The prizes in Wheel of fortune will be randomly distributed during the campaign. <br/><br/>' +
            '5. The leaderboard is consolidated for all operators participating in this Wheel of Fortune.<br/><br/>' +
            '6. The minimum bet required to participate in the Wheel of Fortune Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '7. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '8. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '9. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '10. The Wheel of Fortune has a list of qualified games and a prize pool (EUR) distributed among the winners according to the campaign rules.The Wheel of Fortune has its own mechanic of winning prizes at any time during the event period. The Wheel of Fortune is granted randomly, and all players have the same chance of winning wheel in every qualifying spin. Half of the sectors on the Wheel of Fortune yield winnings, while the remaining half contain zero - the player&#39;s chance of winning is 50%.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Wheel of Fortune Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Wheel of Fortune Campaign two (2) days before the Wheel of Fortune Campaign.<br/><br/>' +
            '17. Submit your participation in the Wheel of Fortune Campaign minimum seven (7) days before the start of the Wheel of Fortune Campaign.',
          placeAndPrize: {
            5: 100,
            10: 40,
            15: 30,
            20: 20,
            50: 15,
            250: 10,
          },
          placeAndPrizeTotal: 50000,
          prizesTotal: '5000 EUR / 350 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Pina Colada Paradise Tournament',
      folderPath: `${promotionsPath}/Pina+Colada+Paradise+Tournament/`,
      period: ['06.09.2024 00:01 (UTC)', '15.09.2024 23:59 (UTC)'],
      description:
        'Sip into September with the Pina Colada Paradise Tournament! Quench your players&#39;  thirst for excitement as they compete for a refreshing slice of the 60,000 EUR prize pool. This tropical adventure is the perfect way to keep the summer vibes alive in your casino. Let the fun flow freely — add the Pina Colada Paradise Tournament to your September lineup!',
      prizePool: 60000,
      market: ['Europe', 'Asia'],
      mechanic: 'Total win',
      minBet: 0.3,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck_bonus_buy',
          name: 'Cycle of Luck Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 6th September 2024 and 15th September 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 60,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 4800,
        2: 3000,
        3: 2400,
        4: 2100,
        5: 1860,
        6: 1740,
        7: 1500,
        8: 1200,
        9: 900,
        10: 600,
        '11-20': 480,
        '21-50': 270,
        '51-100': 150,
        '101-150': 120,
        '151-200': 90,
        '201-300': 60,
        '301-400': 30,
      },
      placeAndPrizeTotal: 60000,
    },
    {
      title: 'Golden Fortune Tournament',
      folderPath: `${promotionsPath}/Golden+Fortune+Tournament/`,
      period: ['10.09.2024 00:01 (UTC+7)', '29.11.2024 23:59 (UTC+7)'],
      description:
        'Welcome to the Golden Fortune Tournament! From September to November, step into a realm of opulence and excitement. With a glittering prize pool of 60,000 EUR up for grabs, this tournament offers an extraordinary chance to strike it rich in the world of Golden Fortune. Join us on this exhilarating journey and claim your share of the treasures that await. Don&#39;t miss out on the golden opportunity of the season!',
      prizePool: 60000,
      market: ['Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess',
          name: 'Book Of The Priestess',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'elven_princesses_crown_quest',
          name: 'Elven Princesses: Crown Quest',
        },
        {
          gameCode: 'legacy_of_the_sages',
          name: 'Legacy of the Sages',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Tournament consists of 3 (three) Phases, which take place on September 10-19, October 15-24, November 20-29. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournament prize pool amount is 60,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract in the corresponding month in 2024.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. If the player&#39;s currency is different from the established exchange rate (EUR, CNY, JPY, KRW, THB, VND, IDR) the conversion rate locked at the beginning of the Tournament will be used instead.<br/><br/>' +
        '9. Each of the tournaments has its list of qualified games and a prize pool, which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '10. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '11. Every qualified spin contributes to the player&#39;s total score, shown on the leaderboard. The total score is calculated by adding the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '12. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '13. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '14. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of the promotion.<br/><br/>' +
        '15. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '16. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '17. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '18. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '19. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '20. Submit your participation in the tournament minimum three (3) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Golden+Fortune+Tournament/campaigns/Phase+1/`,
          period: ['10.09.2024 00:01 (UTC+7)', '19.09.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Sum Multiplier',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Golden+Fortune+Tournament/campaigns/Phase+2/`,
          period: ['15.10.2024 00:01 (UTC+7)', '24.10.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Sum Multiplier',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 3',
          folderPath: `${promotionsPath}/Golden+Fortune+Tournament/campaigns/Phase+3/`,
          period: ['20.11.2024 00:01 (UTC+7)', '29.11.2024 23:59 (UTC+7)'],
          prizePool: 20000,
          mechanic: 'Sum Multiplier',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
      ],
    },
    {
      title: 'Harvest of Fortune Tournament',
      folderPath: `${promotionsPath}/Harvest+of+Fortune+Tournament/`,
      period: ['08.10.2024 00:01 (UTC)', '17.10.2024 23:59 (UTC)'],
      description:
        'Embark on an adventure like no other with the Treasures Hunting Tournament! This July, dive deep into the excitement and compete for a share of the grand 30,000 EUR prize pool. Unearth hidden riches and claim your fortune in this thrilling quest. Join the Treasures Hunting Tournament and let the search for gold begin!',
      prizePool: 30000,
      market: ['MGA'],
      mechanic: 'Total Win',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess_b_b',
          name: 'Book Of The Priestess Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          name: 'Gold of Sirens Bonus Buy',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'money_minter_b_b',
          name: 'Money Minter Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'europe_transit_b_b',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'redrose_sanctuary',
          name: 'Redrose Sanctuary',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 8th October 2024 and 17th October 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 30,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 2100,
        4: 1800,
        5: 1650,
        6: 1500,
        7: 1200,
        8: 1050,
        9: 900,
        10: 600,
        '11-20': 270,
        '21-50': 120,
        '51-100': 90,
        '101-150': 60,
      },
      placeAndPrizeTotal: 30000,
    },
    {
      title: 'Trick or Treat Campaign',
      folderPath: `${promotionsPath}/Trick+or+Treat+Campaign/`,
      period: ['22.10.2024 00:01 (UTC)', '31.10.2024 23:59 (UTC)'],
      description:
        'Get ready for the Trick or Treat Campaign this October! With a spook-tacular 110,000 EUR prize pool, the Halloween fun comes in multiple ways to win — from a thrilling tournament, spins on the Wheel of Fortune, to exciting random prize drops. Whether you&#39;re feeling lucky or daring, there are plenty of treats (and maybe a trick or two) waiting for you. Don&#39t miss out on the frightful fun — join the Trick or Treat Campaign now and grab your share of the 110,000 EUR prize!',
      prizePool: 110000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Wheel', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'ocean_catch_bb',
          name: 'Ocean Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
        {
          gameCode: 'bulls_club',
          name: "Bull's Club",
        },
        {
          gameCode: 'good_luck_spell',
          name: 'Good Luck Spell',
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'chained_fighters',
          name: 'Chained Fighters',
        },
        {
          gameCode: 'venice_festival',
          name: 'Venice Festival',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
        {
          gameCode: 'cursed_can_bb',
          name: 'Cursed Can Bonus Buy',
        },
        {
          gameCode: 'pumpkin_master',
          name: 'Pumpkin Master',
        },
        {
          gameCode: 'redrose_sanctuary_b_b',
          name: 'Redrose Sanctuary Bonus Buy',
        },
        {
          gameCode: 'rueda_de_chile_b_b',
          name: 'Rueda de Chile Bonus Buy',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two phases. First phase consists of Treat or Trick Tournament: Phase I and Treat or Trick Wheel of Fortune Campaign that happen simultaneously. Second phase consists of Treat or Trick Tournament: Phase II and Treat or Trick Prize Drops Campaign that happen simultaneously, too.<br/><br/>' +
        '2. Evoplay Campaign runs for  ten (10) agreed days and takes place between 00:01 UTC on 22th October 2024 and 31th October 2024 until 23:59 UTC. <br/><br/>' +
        '3. The total tournament prize amount is 110,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '9. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '10. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '11. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Trick or Treat Tournament Phase 1',
          folderPath: `${promotionsPath}/Trick+or+Treat+Campaign/campaigns/Trick+or+Treat+Tournament+Phase+1/`,
          period: ['22.10.2024 00:01 (UTC)', '26.10.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Spin',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for five (5) agreed days and takes place between 00:01 UTC on 22nd October 2024 and 26st October 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Trick or Treat Tournament Phase 2',
          folderPath: `${promotionsPath}/Trick+or+Treat+Campaign/campaigns/Trick+or+Treat+Tournament+Phase+2/`,
          period: ['27.10.2024 00:01 (UTC)', '31.10.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Win',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for five (5) agreed days and takes place between 00:01 UTC on 27nd October 2024 and 31 st October 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Trick or Treat Wheel of Fortune',
          folderPath: `${promotionsPath}/Trick+or+Treat+Campaign/campaigns/Trick+or+Treat+Wheel+of+Fortune/`,
          period: ['22.10.2024 00:01 (UTC)', '26.09.2024 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Wheel of Fortune',
          minBet: 0.3,
          tags: ['Wheel'],
          termsAndConditions:
            '1. Evoplay Wheel of Fortune is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Wheel of Fortune runs for  five (5) agreed days and takes place between 00:01 UTC on 22th October 2024 and th 26 October 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 20,000 EUR divided into 1350 prizes. Every qualified spin can trigger a wheel of fortune. <br/><br/>' +
            '4. The prizes in Wheel of fortune will be randomly distributed during the campaign. <br/><br/>' +
            '5. The leaderboard is consolidated for all operators participating in this Wheel of Fortune.<br/><br/>' +
            '6. The minimum bet required to participate in the Wheel of Fortune Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '7. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '8. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '9. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '10. The Wheel of Fortune has a list of qualified games and a prize pool (EUR) distributed among the winners according to the campaign rules.The Wheel of Fortune has its own mechanic of winning prizes at any time during the event period. The Wheel of Fortune is granted randomly, and all players have the same chance of winning wheel in every qualifying spin. Half of the sectors on the Wheel of Fortune yield winnings, while the remaining half contain zero - the player&#39;s chance of winning is 50%.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Wheel of Fortune Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Wheel of Fortune Campaign two (2) days before the Wheel of Fortune Campaign.<br/><br/>' +
            '17. Submit your participation in the Wheel of Fortune Campaign minimum seven (7) days before the start of the Wheel of Fortune Campaign.',
          placeAndPrize: {
            3: 100,
            7: 50,
            15: 30,
            45: 20,
            200: 10,
          },
          placeAndPrizeTotal: 20000,
          prizesTotal: '4000 EUR / 270 prizes daily',
          isPrizeDrops: true,
        },
        {
          title: 'Trick or Treat Prize Drops',
          folderPath: `${promotionsPath}/Trick+or+Treat+Campaign/campaigns/Trick+or+Treat+Prize+Drops/`,
          period: ['27.10.2024 00:01 (UTC)', '31.10.2024 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Random Prize Drops',
          minBet: 0.3,
          tags: ['Prize'],
          termsAndConditions:
            '1. Evoplay Prize Drops Campaign is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Prize Drops Campaign runs for five (5) agreed days and takes place between 00:01 UTC on 27nd October 2024 and 31 st October 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 20,000 EUR divided into  prizes. Every qualified spin can trigger a random prize from the prize pool. The prizes will be randomly distributed during the campaign.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this Prize Drops Campaign.<br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            3: 100,
            7: 50,
            15: 30,
            45: 20,
            200: 10,
          },
          placeAndPrizeTotal: 20000,
          prizesTotal: '4000 EUR / 270 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Trick or Treat Tournament',
      folderPath: `${promotionsPath}/Trick+or+Treat+Tournament/`,
      period: ['22.10.2024 00:01 (UTC+7)', '31.10.2024 23:59 (UTC+7)'],
      description:
        'Get ready for the spine-chilling Trick or Treat Tournament this October! Embrace the Halloween spirit with spooky surprises and monstrous rewards. With a prize pool of 70,000 EUR, this tournament promises thrilling gameplay and big wins. Step into the eerie world of Trick or Treat, and let the Halloween magic bring you unforgettable rewards. Play now and claim your share of the treats — if you dare!',
      prizePool: 70000,
      market: ['Europe'],
      minBet: 0.3,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'ocean_catch_bb',
          name: 'Ocean Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
        {
          gameCode: 'bulls_club',
          name: "Bull's Club",
        },
        {
          gameCode: 'good_luck_spell',
          name: 'Good Luck Spell',
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'chained_fighters',
          name: 'Chained Fighters',
        },
        {
          gameCode: 'venice_festival',
          name: 'Venice Festival',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
        {
          gameCode: 'cursed_can_bb',
          name: 'Cursed Can Bonus Buy',
        },
        {
          gameCode: 'pumpkin_master',
          name: 'Pumpkin Master',
        },
        {
          gameCode: 'redrose_sanctuary_b_b',
          name: 'Redrose Sanctuary Bonus Buy',
        },
        {
          gameCode: 'rueda_de_chile_b_b',
          name: 'Rueda de Chile Bonus Buy',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for five (10) agreed days and takes place between 00:01 UTC on 22nd October 2024 and 31st October 2024 until 23:59 UTC. Tournament consists of 2 phases, each phase runs for five (5) agreed days.<br/><br/>' +
        '3. The total tournament prize amount is 70,000 EUR and will be distributed among the top 400 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. Each phase has its own its qualified mechanic.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Trick+or+Treat+Tournament/campaigns/Phase+1/`,
          period: ['22.10.2024 00:01 (UTC)', '26.10.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Spin',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for five (5) agreed days and takes place between 00:01 UTC on 22nd October 2024 and 26st October 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3500,
            2: 2800,
            3: 2450,
            4: 2100,
            5: 1750,
            6: 1400,
            7: 1155,
            8: 980,
            9: 910,
            10: 455,
            '11-20': 280,
            '21-50': 140,
            '51-100': 105,
            '101-150': 70,
            '151-200': 35,
          },
          placeAndPrizeTotal: 35000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Trick+or+Treat+Tournament/campaigns/Phase+2/`,
          period: ['27.10.2024 00:01 (UTC)', '31.10.2024 23:59 (UTC)'],
          prizePool: 35000,
          mechanic: 'Total Win',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for five (5) agreed days and takes place between 00:01 UTC on 27nd October 2024 and 31 st October 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 35,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 2000,
            2: 1500,
            3: 1200,
            4: 1000,
            5: 700,
            '6-10': 500,
            '11-20': 300,
            '21-30': 200,
            '31-40': 100,
            '41-60': 80,
            '61-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
      ],
    },
    {
      title: 'Spinvember Campaign',
      folderPath: `${promotionsPath}/Spinvember+Campaign/`,
      period: ['08.11.2024 00:01 (UTC)', '19.11.2024 23:59 (UTC)'],
      description:
        'Introducing the Spinvember Campaign! This thrilling event offers a €120,000 prize pool split across two weeks of non-stop excitement. In the first week, join the Spinvember Tournament and compete for an incredible €75,000 prize pool. Then, in the second week, take a chance at the Wheel of Fortune with an additional €45,000 in prizes up for grabs. Two weeks of spins, surprises, and fortune await — make this November unforgettable for your players!',
      prizePool: 120000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Wheel'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
        {
          gameCode: 'bulls_club',
          name: "Bull's Club",
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess_b_b',
          name: 'Book Of The Priestess Bonus Buy',
        },
        {
          gameCode: 'chained_fighters',
          name: 'Chained Fighters',
        },
        {
          gameCode: 'good_luck_spell',
          name: 'Good Luck Spell',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two tournaments that happen in sequen: Spinvember  Tournament and Spinvember Wheel of Fortune Campaign.<br/><br/>' +
        '2. Evoplay Campaign runs for twelve (12) agreed days and takes place between 00:01 UTC on 8th November 2024 and 19th November 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 120,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '9. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '10. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '11. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '12. A casino must support the tournament by implementing the list of marketing activities provided below..<br/><br/>' +
        '13. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '14. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Spinvember Tournament',
          folderPath: `${promotionsPath}/Spinvember+Campaign/campaigns/Spinvember+Tournament/`,
          period: ['08.11.2024 00:01 (UTC)', '13.11.2024 23:59 (UTC)'],
          prizePool: 75000,
          mechanic: 'Sum Multiplier',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for six (6) agreed days and takes place between 00:01 UTC on 8th November 2024 and 13th November 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 75,000 EUR and will be distributed among the top 600 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 4500,
            2: 3750,
            3: 3000,
            4: 2700,
            5: 2400,
            6: 1800,
            7: 1500,
            8: 1350,
            9: 1200,
            10: 1050,
            '11-20': 750,
            '21-50': 450,
            '51-100': 150,
            '101-150': 90,
            '151-200': 60,
            '201-250': 60,
            '251-400': 45,
            '401-600': 30,
          },
          placeAndPrizeTotal: 75000,
        },
        {
          title: 'Spinvember Wheel of Fortune',
          folderPath: `${promotionsPath}/Spinvember+Campaign/campaigns/Spinvember+Wheel+of+Fortune/`,
          period: ['14.11.2024 00:01 (UTC)', '19.11.2024 23:59 (UTC)'],
          prizePool: 45000,
          mechanic: 'Wheel of Fortune',
          minBet: 0.3,
          tags: ['Wheel'],
          termsAndConditions:
            '1. Evoplay Wheel of Fortune is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Wheel of Fortune runs for six (6) agreed days and takes place between 00:01 UTC on 14th November 2024 and th 19th November 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 45,000 EUR divided into 3060 prizes. Every qualified spin can trigger a wheel of fortune. <br/><br/>' +
            '4. The prizes in Wheel of fortune will be randomly distributed during the campaign. <br/><br/>' +
            '5. The leaderboard is consolidated for all operators participating in this Wheel of Fortune.<br/><br/>' +
            '6. The minimum bet required to participate in the Wheel of Fortune Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '7. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '8. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '9. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '10. The Wheel of Fortune has a list of qualified games and a prize pool (EUR) distributed among the winners according to the campaign rules.The Wheel of Fortune has its own mechanic of winning prizes at any time during the event period. The Wheel of Fortune is granted randomly, and all players have the same chance of winning wheel in every qualifying spin. Half of the sectors on the Wheel of Fortune yield winnings, while the remaining half contain zero - the player&#39;s chance of winning is 50%.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Wheel of Fortune Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Wheel of Fortune Campaign two (2) days before the Wheel of Fortune Campaign.<br/><br/>' +
            '17. Submit your participation in the Wheel of Fortune Campaign minimum seven (7) days before the start of the Wheel of Fortune Campaign.',
          placeAndPrize: {
            5: 65,
            10: 50,
            20: 40,
            25: 25,
            150: 15,
            300: 10,
          },
          placeAndPrizeTotal: 45000,
          prizesTotal: '7500 EUR / 510 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Spinvember Tournament',
      folderPath: `${promotionsPath}/Spinvember+Tournament/`,
      period: ['08.11.2024 00:01 (UTC)', '13.11.2024 23:59 (UTC)'],
      description:
        'Get ready for Spinvember Tournament! This November, spin your way to incredible rewards with a massive €75,000 prize pool. As the days grow shorter, the wins get bigger — embrace the thrill of every spin and claim your share of autumn riches. Don&#39;t miss out on the excitement this Spinvember, where fortune awaits with every turn of the reels!',
      prizePool: 75000,
      market: ['Europe', 'Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.3,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
        {
          gameCode: 'bulls_club',
          name: "Bull's Club",
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess_b_b',
          name: 'Book Of The Priestess Bonus Buy',
        },
        {
          gameCode: 'chained_fighters',
          name: 'Chained Fighters',
        },
        {
          gameCode: 'good_luck_spell',
          name: 'Good Luck Spell',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for six (6) agreed days and takes place between 00:01 UTC on 8th November 2024 and 13th November 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 75,000 EUR and will be distributed among the top 600 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 4500,
        2: 3750,
        3: 3000,
        4: 2700,
        5: 2400,
        6: 1800,
        7: 1500,
        8: 1350,
        9: 1200,
        10: 1050,
        '11-20': 750,
        '21-50': 450,
        '51-100': 150,
        '101-150': 90,
        '151-200': 60,
        '201-250': 60,
        '251-400': 45,
        '401-600': 30,
      },
      placeAndPrizeTotal: 75000,
    },
    {
      title: 'Merry Wins Tournament',
      folderPath: `${promotionsPath}/Merry+Wins+Tournament/`,
      period: ['20.12.2024 00:01 (UTC)', '29.12.2024 23:59 (UTC)'],
      description:
        'Welcome to the Merry Wins Network Tournament! This December, get ready for a spectacular competition with a prize pool of 50,000 EUR. The Merry Wins Tournament invites you to celebrate the season with thrilling gameplay and the chance to claim amazing rewards. Don&#39;t miss your opportunity to be part of this festive tournament and win big!',
      prizePool: 50000,
      market: ['MGA'],
      mechanic: 'Sum Multiplier',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'redrose_sanctuary',
          name: 'Redrose Sanctuary',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
        {
          gameCode: 'juicy_gems',
          name: 'Juicy Gems',
        },
        {
          gameCode: 'gold_of_sirens',
          name: 'Gold of Sirens',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'temple_of_thunder2',
          name: 'Temple of Thunder II',
        },
        {
          gameCode: 'christmas_reach',
          name: 'Christmas Reach',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 20th December 2024 and 29th December 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 300 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 4000,
        2: 3500,
        3: 3000,
        4: 2250,
        5: 2000,
        6: 1750,
        7: 1500,
        8: 1250,
        9: 1000,
        10: 750,
        '11-20': 500,
        '21-50': 300,
        '51-100': 200,
        '101-150': 100,
        '151-200': 75,
        '201-300': 50,
      },
      placeAndPrizeTotal: 50000,
    },
    {
      title: 'Christmas Treasures Tournament',
      folderPath: `${promotionsPath}/Christmas+Treasures+Tournament/`,
      period: ['02.12.2024 00:01 (UTC)', '29.12.2024 23:59 (UTC)'],
      description:
        'Step into the Christmas Treasures Tournament! This December, dive into a season of grand rewards with a stunning prize pool of 500,000 EUR. The Christmas Treasures Tournament brings you a chance to uncover incredible riches and celebrate the holiday spirit with unforgettable wins. Seize this incredible chance to uncover the treasures of the season!',
      prizePool: 500000,
      market: ['Europe'],
      minBet: 0.3,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'bulls_club',
          name: "Bull's Club",
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'dolce_dreams',
          name: 'Dolce Dreams',
        },
        {
          gameCode: 'hot_rio_nights',
          name: 'Hot Rio Nights',
        },
        {
          gameCode: 'treasure_snipes_christmas_b_b',
          name: 'Treasure-snipes: Christmas Bonus Buy',
        },
        {
          gameCode: 'christmas_reach_bonus_buy',
          name: 'Christmas Reach Bonus Buy',
        },
        {
          gameCode: 'christmas_crash',
          name: 'Christmas Crash',
        },
        {
          gameCode: 'tap_craze',
          name: 'Tap Craze',
        },
        {
          gameCode: 'hot_triple_sevens_hw',
          name: 'Hot Triple Sevens Hold & Win',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for twenty-eight(28) agreed days and takes place  between 00:01 UTC on 2nd December  2024 and 29th December 2024 until 23:59 UTC. Evoplay Network Tournament consists of 4 phases, each phase runs for seven (7) agreed days.<br/><br/>' +
        '3. The total tournament prize amount is 500,000 EUR. Each phase&#39;s prize pool is 125,000 EUR and will be distributed among the top 1000 players with the highest results on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. Each phase has its own its qualified mechanic.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Christmas+Treasures+Tournament/campaigns/Phase+1/`,
          period: ['02.12.2024 00:01 (UTC)', '08.12.2024 23:59 (UTC)'],
          prizePool: 125000,
          mechanic: 'Sum Multiplier',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 2nd December  2024 and 8th December 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 125,000 EUR and will be distributed among the top 1000 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic:Sum Multiplier accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 5000,
            2: 3750,
            3: 3250,
            4: 3000,
            5: 2500,
            6: 2250,
            7: 2000,
            8: 1750,
            9: 1500,
            10: 1250,
            '11-20': 750,
            '21-50': 500,
            '51-100': 225,
            '101-150': 175,
            '151-200': 125,
            '201-300': 100,
            '301-500': 75,
            '501-1000': 50,
          },
          placeAndPrizeTotal: 125000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Christmas+Treasures+Tournament/campaigns/Phase+2/`,
          period: ['09.12.2024 00:01 (UTC)', '15.12.2024 23:59 (UTC)'],
          prizePool: 125000,
          mechanic: 'Total Spin',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 9th December  2024 and 15th December 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 125,000 EUR and will be distributed among the top 1000 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic:  Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 5000,
            2: 3750,
            3: 3250,
            4: 3000,
            5: 2500,
            6: 2250,
            7: 2000,
            8: 1750,
            9: 1500,
            10: 1250,
            '11-20': 750,
            '21-50': 500,
            '51-100': 225,
            '101-150': 175,
            '151-200': 125,
            '201-300': 100,
            '301-500': 75,
            '501-1000': 50,
          },
          placeAndPrizeTotal: 125000,
        },
        {
          title: 'Phase 3',
          folderPath: `${promotionsPath}/Christmas+Treasures+Tournament/campaigns/Phase+3/`,
          period: ['16.12.2024 00:01 (UTC)', '22.12.2024 23:59 (UTC)'],
          prizePool: 125000,
          mechanic: 'Sum Multiplier',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 16th December  2024 and 22th December 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 125,000 EUR and will be distributed among the top 1000 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic:Sum Multiplier accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 5000,
            2: 3750,
            3: 3250,
            4: 3000,
            5: 2500,
            6: 2250,
            7: 2000,
            8: 1750,
            9: 1500,
            10: 1250,
            '11-20': 750,
            '21-50': 500,
            '51-100': 225,
            '101-150': 175,
            '151-200': 125,
            '201-300': 100,
            '301-500': 75,
            '501-1000': 50,
          },
          placeAndPrizeTotal: 125000,
        },
        {
          title: 'Phase 4',
          folderPath: `${promotionsPath}/Christmas+Treasures+Tournament/campaigns/Phase+4/`,
          period: ['23.12.2024 00:01 (UTC)', '29.12.2024 23:59 (UTC)'],
          prizePool: 125000,
          mechanic: 'Total Spin',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 23th December  2024 and 29th December 2024 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 125,000 EUR and will be distributed among the top 1000 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic:  Total Spin accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 5000,
            2: 3750,
            3: 3250,
            4: 3000,
            5: 2500,
            6: 2250,
            7: 2000,
            8: 1750,
            9: 1500,
            10: 1250,
            '11-20': 750,
            '21-50': 500,
            '51-100': 225,
            '101-150': 175,
            '151-200': 125,
            '201-300': 100,
            '301-500': 75,
            '501-1000': 50,
          },
          placeAndPrizeTotal: 125000,
        },
      ],
    },
    {
      title: "Dragon's Treasures Tournament",
      folderPath: `${promotionsPath}/Dragon’s+Treasures+Tournament/`,
      period: ['22.01.2025 00:01 (UTC+7)', '10.02.2025 23:59 (UTC+7)'],
      description:
        "Embark on an epic adventure with the Dragon's Treasures Tournament! From January to February, enter a world of mythic riches and compete for a fiery prize pool of 60,000 EUR. The Dragon's Treasures Tournament offers daring challengers the chance to claim legendary rewards. Don't let this thrilling opportunity slip away — unleash your courage and conquer the treasures of the dragon!",
      prizePool: 60000,
      market: ['Asia'],
      mechanic: 'Total Win',
      minBet: 0.2,
      languages: ['en', 'zh', 'ja', 'ko', 'id', 'th', 'vi'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'jhana_of_god_b_b',
          name: 'Jhana of God Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'elven_princesses_crown_quest',
          name: 'Elven Princesses: Crown Quest',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'juicy_gems_b_b',
          name: 'Juicy Gems Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'bandit_bust',
          name: 'Bandit Bust',
        },
        {
          gameCode: 'goblin_run',
          name: 'Goblin Run',
        },
        {
          gameCode: 'legacy_of_the_sages',
          name: 'Legacy of the Sages',
        },
        {
          gameCode: 'x_demon_b_b',
          name: 'X-Demon Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Tournament consists of 2 (two) Phases, which take place on 22-31 January, 1-10 February. The Phases start at 00:01 +7 UTC and finish at 23:59 +7 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournament prize pool amount is 60,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in every Phase is 0,2 EUR.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract in the corresponding month in 2024.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. If the player&#39;s currency is different from the established exchange rate (EUR, CNY, JPY, KRW, THB, VND, IDR) the conversion rate locked at the beginning of the Tournament will be used instead.<br/><br/>' +
        '9. Each of the tournaments has its list of qualified games and a prize pool, which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '10. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '11. Every qualified spin contributes to the player&#39;s total score, shown on the leaderboard. The total score is calculated by adding the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '12. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '13. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '14. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of the promotion.<br/><br/>' +
        '15. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '16. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '17. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '18. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '19. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '20. Submit your participation in the tournament minimum three (3) days before the start of each Phase.',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Dragon’s+Treasures+Tournament/campaigns/Phase+1/`,
          period: ['22.01.2025 00:01 (UTC+7)', '31.01.2025 23:59 (UTC+7)'],
          prizePool: 30000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2100,
            2: 1800,
            3: 1500,
            4: 1050,
            5: 600,
            '6-10': 300,
            '11-20': 270,
            '21-30': 240,
            '31-40': 210,
            '41-60': 120,
            '61-100': 90,
            '101-150': 60,
            '151-200': 45,
            '201-300': 30,
          },
          placeAndPrizeTotal: 30000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Dragon’s+Treasures+Tournament/campaigns/Phase+2/`,
          period: ['01.02.2025 00:01 (UTC+7)', '10.02.2025 23:59 (UC+7)'],
          prizePool: 30000,
          mechanic: 'Total Win',
          minBet: 0.2,
          tags: ['Network'],
          placeAndPrize: {
            1: 2100,
            2: 1800,
            3: 1500,
            4: 1050,
            5: 600,
            '6-10': 300,
            '11-20': 270,
            '21-30': 240,
            '31-40': 210,
            '41-60': 120,
            '61-100': 90,
            '101-150': 60,
            '151-200': 45,
            '201-300': 30,
          },
          placeAndPrizeTotal: 30000,
        },
      ],
    },
    {
      title: 'Winter Fairy-Tale Tournament',
      folderPath: `${promotionsPath}/Winter+Fairy-Tale+Tournament/`,
      period: ['20.01.2025 00:01 (UTC)', '26.01.2025 23:59 (UTC)'],
      description:
        "Step into a magical world with the Winter Fairy-Tale Tournament! This January, experience the enchantment of winter as you compete for a spellbinding prize pool of 30,000 EUR. The Winter Fairy-Tale Tournament promises an unforgettable adventure filled with wonder and rewards. Don't miss your chance to make this winter truly extraordinary!",
      prizePool: 30000,
      market: ['MGA'],
      mechanic: 'Sum Multiplier',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'fruit_super_nova_100',
          name: 'Fruit Super Nova 100',
        },
        {
          gameCode: 'hot_volcano',
          name: 'Hot Volcano',
        },
        {
          gameCode: 'triple_chili',
          name: 'Triple Chili',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'camino_de_chili_b_b',
          name: 'Camino de Chili Bonus Buy',
        },
        {
          gameCode: 'book_of_the_priestess_b_b',
          name: 'Book Of The Priestess Bonus Buy',
        },
        {
          gameCode: 'gold_of_sirens_bonus_buy',
          name: 'Gold of Sirens Bonus Buy',
        },
        {
          gameCode: 'unlimited_treasures',
          name: 'Unlimited Treasures',
        },
        {
          gameCode: 'money_minter_b_b',
          name: 'Money Minter Bonus Buy',
        },
        {
          gameCode: 'cycle_of_luck',
          name: 'Cycle of Luck',
        },
        {
          gameCode: 'europe_transit_b_b',
          name: 'Europe Transit Bonus Buy',
        },
        {
          gameCode: 'redrose_sanctuary',
          name: 'Redrose Sanctuary',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 20th January 2025 and 26th January 2025 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 2100,
        4: 1800,
        5: 1650,
        6: 1500,
        7: 1200,
        8: 1050,
        9: 900,
        10: 600,
        '11-20': 270,
        '21-50': 120,
        '51-100': 90,
        '101-150': 60,
      },
      placeAndPrizeTotal: 30000,
    },
    {
      title: 'Evoplay Races Tournament',
      folderPath: `${promotionsPath}/Evoplay+Races/`,
      period: ['13.01.2025 00:01 (UTC)', '19.01.2025 23:59 (UTC)'],
      description:
        "Get ready to rev up the action in the Evoplay Race Tournament! This January, compete for an adrenaline-pumping prize pool of 210,000 EUR. The Evoplay Race Tournament challenges players to push their limits and claim victory in a thrilling competition. Don't miss your chance to cross the finish line and take home epic rewards!",
      prizePool: 210000,
      market: ['Europe, Asia'],
      mechanic: 'Sum Multiplier',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'hot_mania',
          name: 'Hot Mania',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'candy_craze',
          name: 'Candy Craze',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'roman_rule',
          name: 'Roman Rule',
        },
        {
          gameCode: 'ocean_catch_bb',
          name: 'Ocean Catch Bonus Buy',
        },
        {
          gameCode: 'budai_reels_bonus_buy',
          name: 'Budai Reels Bonus Buy',
        },
        {
          gameCode: 'thunder_mega_sevens_b_b',
          name: 'Thunder Mega Sevens Bonus Buy',
        },
        {
          gameCode: 'bandit_bust_bb',
          name: 'Bandit Bust Bonus Buy',
        },
        {
          gameCode: 'belfry_bliss',
          name: 'Belfry Bliss',
        },
        {
          gameCode: 'young_wolf_song',
          name: 'Young Wolf Song',
        },
        {
          gameCode: 'bulls_club',
          name: "Bull's Club",
        },
        {
          gameCode: 'good_luck_spell',
          name: 'Good Luck Spell',
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'chained_fighters',
          name: 'Chained Fighters',
        },
        {
          gameCode: 'venice_festival',
          name: 'Venice Festival',
        },
        {
          gameCode: 'hot_rio_nights_b_b',
          name: 'Hot Rio Nights Bonus Buy',
        },
        {
          gameCode: 'battle_roosters',
          name: 'Battle Roosters',
        },
        {
          gameCode: 'collapsed_castle',
          name: 'Collapsed Castle',
        },
        {
          gameCode: 'dolce_dreams',
          name: 'Dolce Dreams',
        },
        {
          gameCode: 'hercules_sports_legend',
          name: 'Hercules: Sports Legend',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs  for seven (7) agreed days and takes place between 00:01 UTC on 13th January 2025 and 19th January 2025 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 210,000 EUR and will be distributed among the top 1500 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,50 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 6000,
        2: 5000,
        3: 4500,
        4: 4000,
        5: 3500,
        6: 3000,
        7: 2500,
        8: 2000,
        9: 1500,
        10: 1000,
        '11-30': 700,
        '31-50': 500,
        '51-100': 360,
        '101-500': 150,
        '501-1000': 100,
        '1001-1500': 50,
      },
      placeAndPrizeTotal: 210000,
    },
    {
      title: 'Lucky in Love Campaign',
      folderPath: `${promotionsPath}/Lucky+in+Love+Campaign/`,
      period: ['14.02.2025 00:01 (UTC)', '23.02.2025 23:59 (UTC)'],
      description:
        "Get ready to fall in love with the thrill Lucky in Love Campaign this Valentine's Day! This February, ignite your passion for gaming and compete for a heart-racing prize pool of 90,000 EUR. Lucky in Love Campaign invites players to embrace the spirit of love and luck, spinning their way to rewards and unforgettable wins. Don't miss your chance to capture the ultimate prize and let fortune be your Valentine.",
      prizePool: 90000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network', 'Wheel', 'Prize'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens_hw',
          name: 'Hot Triple Sevens Hold & Win',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'sea_of_wealth',
          name: 'Sea of Wealth',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'goblin_run',
          name: 'Goblin Run',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'pyro_joker_evo',
          name: 'Pyro Joker',
        },
        {
          gameCode: 'europe_transit_snowdrift',
          name: 'Europe Transit Snowdrift',
        },
        {
          gameCode: 'tap_craze',
          name: 'Tap Craze',
        },
      ],
      termsAndConditions:
        '1. Evoplay Campaign is available to play in real mode only and consist of two tournaments that happen in sequen: Lucky in Love Tournament, Lucky in Love Wheel of Fortune Campaign, Lucky in Love and Prize Drops Campaign.<br/><br/>' +
        '2. Evoplay Campaign runs for ten (10) agreed days and takes place between 00:01 UTC on 14th February 2025 and 23rd February 2025 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 90,000 EUR.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network campaign.<br/><br/>' +
        '5. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '6. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '7. The campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '8. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard.<br/><br/>' +
        '9. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '10. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '11. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '12. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '13. Players will receive a pop-up announcement about the start of the campaign two (2) days before the tournament.<br/><br/>' +
        '14. Submit your participation in the campaign minimum seven (7) days before the start of the campaign.',
      phases: [
        {
          title: 'Lucky in Love Tournament',
          folderPath: `${promotionsPath}/Lucky+in+Love+Campaign/campaigns/Lucky+in+Love+Tournament/`,
          period: ['14.02.2025 00:01 (UTC)', '23.02.2025 23:59 (UTC)'],
          prizePool: 50000,
          mechanic: 'Total Win',
          minBet: 0.3,
          tags: ['Network'],
          termsAndConditions:
            '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 14th February 2025 and 23rd February 2025 until 23:59 UTC.<br/><br/>' +
            '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 500 players who have the highest scores on the leaderboards.<br/><br/>' +
            '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
            '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
            '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
            '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
            '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
            '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
            '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
            '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
            '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
            '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
          placeAndPrize: {
            1: 3000,
            2: 2500,
            3: 2000,
            4: 1500,
            5: 1000,
            6: 800,
            7: 700,
            8: 600,
            9: 500,
            10: 400,
            '11-20': 250,
            '21-50': 200,
            '51-100': 150,
            '101-150': 120,
            '151-200': 80,
            '201-300': 50,
            '301-500': 30,
          },
          placeAndPrizeTotal: 50000,
        },
        {
          title: 'Lucky in Love Wheel of Fortune',
          folderPath: `${promotionsPath}/Spinvember+Campaign/campaigns/Spinvember+Wheel+of+Fortune/`,
          period: ['14.02.2025 00:01 (UTC)', '23.02.2025 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Wheel of Fortune',
          minBet: 0.3,
          tags: ['Wheel'],
          termsAndConditions:
            '1. Evoplay Wheel of Fortune is available to play in real mode only.<br/><br/>' +
            '2. Evoplay Wheel of Fortune runs for ten (10) agreed days and takes place between 00:01 UTC on 14th February 2025 and 23rd February 2025 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 20,000 EUR divided into 2500 prizes. Every qualified spin can trigger a wheel of fortune. <br/><br/>' +
            '4. The prizes in Wheel of fortune will be randomly distributed during the campaign. <br/><br/>' +
            '5. The leaderboard is consolidated for all operators participating in this Wheel of Fortune.<br/><br/>' +
            '6. The minimum bet required to participate in the Wheel of Fortune Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '7. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '8. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '9. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '10. The Wheel of Fortune has a list of qualified games and a prize pool (EUR) distributed among the winners according to the campaign rules. The Wheel of Fortune has its own mechanic of winning prizes at any time during the event period. The Wheel of Fortune is granted randomly, and all players have the same chance of winning wheel in every qualifying spin. Half of the sectors on the Wheel of Fortune yield winnings, while the remaining half contain zero - the player&#39;s chance of winning is 50%.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Wheel of Fortune Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Wheel of Fortune Campaign two (2) days before the Wheel of Fortune Campaign.<br/><br/>' +
            '17. Submit your participation in the Wheel of Fortune Campaign minimum seven (7) days before the start of the Wheel of Fortune Campaign.',
          placeAndPrize: {
            5: 50,
            10: 30,
            25: 20,
            50: 9,
            60: 5,
            100: 2,
          },
          placeAndPrizeTotal: 20000,
          prizesTotal: '2000 EUR / 250 prizes daily',
          isPrizeDrops: true,
        },
        {
          title: 'Lucky in Love Prize Drops',
          folderPath: `${promotionsPath}/Lucky+in+Love+Campaign/campaigns/Lucky+in+Love+Prize+Drops/`,
          period: ['14.02.2025 00:01 (UTC)', '23.02.2025 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Random Prize Drop',
          minBet: 0.3,
          tags: ['Prize'],
          termsAndConditions:
            '1.  Evoplay Prize Drops Campaign  is available to play in real mode only.<br/><br/>' +
            '2.  Evoplay Prize Drops Campaign  runs for ten (10) agreed days and takes place between 00:01 UTC on 14th February 2025 and 23rd February 2025 until 23:59 UTC.<br/><br/>' +
            '3. The total prize pool is 20,000 EUR divided into 2500 prizes. Every qualified spin can trigger a wheel of fortune. <br/><br/>' +
            '4. The prizes in Wheel of fortune will be randomly distributed during the campaign. <br/><br/>' +
            '5. The minimum bet required to participate in the Prize Drops Campaign is 0,3 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
            '6. Each player can win maximum amount of 3 prizes per day.<br/><br/>' +
            '7. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
            '8. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
            '9. The Prize Drops Campaign has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the campaign rules.<br/><br/>' +
            '10. The Prize Drops Campaign has its own mechanic of winning cash prizes at any time during the event period. The prizes are granted randomly, and all players have the same chance of winning in every qualifying spin.<br/><br/>' +
            '11. The cash prizes will be automatically credited to the players&#39; accounts as soon as the win is triggered. Once a prize is credited, it will be removed from the prize pool.<br/><br/>' +
            '12. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
            '13. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion, all part of the score results from cheating or collusion with other players.<br/><br/>' +
            '14. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
            '15. A casino must support the Prize Drops Campaign by implementing the list of marketing activities provided below.<br/><br/>' +
            '16. Players will receive a pop-up announcement about the start of the Prize Drops Campaign two (2) days before the Prize Drops Campaign.<br/><br/>' +
            '17. Submit your participation in the Prize Drops minimum seven (7) days before the start of the Prize Drops Campaign.',
          placeAndPrize: {
            5: 50,
            10: 30,
            25: 20,
            50: 9,
            60: 5,
            100: 2,
          },
          placeAndPrizeTotal: 20000,
          prizesTotal: '2000 EUR / 250 prizes daily',
          isPrizeDrops: true,
        },
      ],
    },
    {
      title: 'Lucky in Love Tournament',
      folderPath: `${promotionsPath}/Lucky+in+Love+Tournament/`,
      period: ['14.02.2025 00:01 (UTC)', '23.02.2025 23:59 (UTC)'],
      description:
        "Gear up for the ultimate celebration of luck and love in the Lucky in Love Tournament this February! With a prize pool of 50,000 EUR up for grabs, this is your chance to take on the reels and come out on top. The Lucky in Love Tournament is your arena to show skill, embrace fortune, and claim rewards fit for a champion. Don't let this opportunity slip away — step in, spin, and conquer your way to victory!",
      prizePool: 50000,
      market: ['Europe'],
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'hot_triple_sevens',
          name: 'Hot Triple Sevens',
        },
        {
          gameCode: 'penalty_shoot_out_street',
          name: 'Penalty Shoot-out Street',
        },
        {
          gameCode: 'the_greatest_catch_bonus_buy',
          name: 'The Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'hot_triple_sevens_hw',
          name: 'Hot Triple Sevens Hold & Win',
        },
        {
          gameCode: 'penalty_shoot_out',
          name: 'Penalty Shoot-out',
        },
        {
          gameCode: 'scratch_match',
          name: 'Scratch Match',
        },
        {
          gameCode: 'mega_greatest_catch_b_b',
          name: 'Mega Greatest Catch Bonus Buy',
        },
        {
          gameCode: 'cats_blessing',
          name: "Cat's Blessing",
        },
        {
          gameCode: 'elven_princesses',
          name: 'Elven Princesses',
        },
        {
          gameCode: 'sea_of_wealth',
          name: 'Sea of Wealth',
        },
        {
          gameCode: 'inner_fire_b_b',
          name: 'Inner Fire Bonus Buy',
        },
        {
          gameCode: 'goblin_run',
          name: 'Goblin Run',
        },
        {
          gameCode: 'hot_triple_sevens_special',
          name: 'Hot Triple Sevens Special',
        },
        {
          gameCode: 'fruit_super_nova',
          name: 'Fruit Super Nova',
        },
        {
          gameCode: 'temple_of_thunder2_bb',
          name: 'Temple of Thunder II Bonus Buy',
        },
        {
          gameCode: 'ice_mania',
          name: 'Ice Mania',
        },
        {
          gameCode: 'hot_volcano_b_b',
          name: 'Hot Volcano Bonus Buy',
        },
        {
          gameCode: 'pyro_joker_evo',
          name: 'Pyro Joker',
        },
        {
          gameCode: 'europe_transit_snowdrift',
          name: 'Europe Transit Snowdrift',
        },
        {
          gameCode: 'tap_craze',
          name: 'Tap Craze',
        },
      ],
      termsAndConditions:
        '1. Evoplay Tournament is available to play in real mode only.<br/><br/>' +
        '2. Evoplay Network Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 14th February 2025 and 23rd February 2025 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 50,000 EUR and will be distributed among the top 500 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,30 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Evoplay reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Evoplay further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2500,
        3: 2000,
        4: 1500,
        5: 1000,
        6: 800,
        7: 700,
        8: 600,
        9: 500,
        10: 400,
        '11-20': 250,
        '21-50': 200,
        '51-100': 150,
        '101-150': 120,
        '151-200': 80,
        '201-300': 50,
        '301-500': 30,
      },
      placeAndPrizeTotal: 50000,
    },
  ],
  slotopia: [
    {
      title: 'Cash-Topia from Slotopia',
      folderPath: `${promotionsPath}/Cash-Topia+from+Slotopia/`,
      period: ['20.02.2024 00:01 (UTC)', '26.02.2024 23:59 (UTC)'],
      description:
        'Welcome to the kingdom of classic delights! Introducing Cash-Topia from Spotopia Network Tournament, where nostalgia meets excitement. Dive into the world of timeless symbols &ndash; from luscious fruits to lucky sevens &ndash; and compete for your share of the impressive 20,000 EUR prize pool. It is not just a tournament; it is a journey to Cashtopia, where the thrill of classic gaming reigns supreme. Join now and let the classics unfold!',
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Total Spin',
      minBet: 0.4,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'mega_fruit_20',
          name: 'Mega Fruit 20',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'double_up_machine',
          name: 'Double Up Machine',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'jokers_invasion',
          name: 'Jokers Invasion',
        },
        {
          gameCode: 'mega_juicer_5',
          name: 'Mega Juicer 5',
        },
        {
          gameCode: 'coin_forge_magnetic',
          name: 'Coin Forge Magnetic',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
      ],
      termsAndConditions:
        '1. Cash-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Cash-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 20th February 2024 and 26th February 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament. <br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Prize-Topia from Slotopia',
      folderPath: `${promotionsPath}/Prize-Topia+from+Slotopia/`,
      period: ['04.04.2024 00:01 (UTC)', '10.04.2024 23:59 (UTC)'],
      description:
        'Welcome to Prize-topia from Slotopia! Get ready for an exhilarating gaming experience like no other this April. With a thrilling prize pool of 20,000 EUR up for grabs, this tournament is your ticket to excitement and big wins. Join us in the ultimate gaming adventure and discover the thrill of competing for your share of the prize pool. Don&#39;t miss out on the action &ndash; dive into Prize-topia from Slotopia today!',
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Sum Multiplier',
      minBet: 0.4,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'mega_fruit_20',
          name: 'Mega Fruit 20',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
        {
          gameCode: 'coin_forge',
          name: 'Coin Forge',
        },
        {
          gameCode: 'double_up_machine',
          name: 'Double Up Machine',
        },
        {
          gameCode: 'coin_forge_magnetic',
          name: 'Coin Forge Magnetic',
        },
        {
          gameCode: 'mega_juicer_6',
          name: 'Mega Juicer 6',
        },
        {
          gameCode: 'jokers_invasion',
          name: 'Jokers Invasion',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'mega_fruit_40',
          name: 'Mega Fruit 40',
        },
        {
          gameCode: 'fruit_fantasy_40',
          name: 'Fruit Fantasy 40',
        },
        {
          gameCode: 'wild_double_up',
          name: 'Wild Double Up',
        },
        {
          gameCode: 'mega_juicer_5',
          name: 'Mega Juicer 5',
        },
        {
          gameCode: 'sizzling_sevens',
          name: 'Sizzling Sevens',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'fruit_fantasy_100',
          name: 'Fruit Fantasy 100',
        },
        {
          gameCode: 'joker_juicer_6',
          name: 'Joker Juicer 6',
        },
        {
          gameCode: 'dragon_balls',
          name: 'Dragon Balls',
        },
        {
          gameCode: 'lucky_fruit_spins_bb',
          name: 'Lucky Fruit Spins Bonus Buy',
        },
        {
          gameCode: 'sizzling_super_sevens',
          name: 'Sizzling Super Sevens',
        },
        {
          gameCode: 'book_of_reels',
          name: 'Book of Reels',
        },
        {
          gameCode: 'green_hat_tale',
          name: 'Green Hat Tale',
        },
        {
          gameCode: 'mega_diamonds_hunt',
          name: 'Mega Diamonds Hunt',
        },
        {
          gameCode: 'coin_forge_bb',
          name: 'Coin Forge Bonus Buy',
        },
      ],
      termsAndConditions:
        '1. Prize-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Prize-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 4th April 2024 and 10th April 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities provided below.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Spin-Topia from Slotopia',
      folderPath: `${promotionsPath}/Spin-Topia+from+Slotopia/`,
      period: ['03.05.2024 00:01 (UTC)', '12.05.2024 23:59 (UTC)'],
      description:
        'Introducing Spin-Topia from Slotopia! Prepare for an exciting gaming adventure this May. With an enticing prize pool of 30,000 EUR awaiting, this tournament promises non-stop excitement and the chance to win big. Mark your calendars for May 3rd to 12th and join us for an unforgettable gaming experience. Don&#39;t miss out on the thrill - dive into Spin-Topia from Slotopia today!',
      prizePool: 30000,
      market: ['Europe'],
      mechanic: 'Total Win',
      minBet: 0.4,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'mega_fruit_20',
          name: 'Mega Fruit 20',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
        {
          gameCode: 'coin_forge',
          name: 'Coin Forge',
        },
        {
          gameCode: 'double_up_machine',
          name: 'Double Up Machine',
        },
        {
          gameCode: 'coin_forge_magnetic',
          name: 'Coin Forge Magnetic',
        },
        {
          gameCode: 'mega_juicer_6',
          name: 'Mega Juicer 6',
        },
        {
          gameCode: 'jokers_invasion',
          name: 'Jokers Invasion',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'mega_fruit_40',
          name: 'Mega Fruit 40',
        },
        {
          gameCode: 'fruit_fantasy_40',
          name: 'Fruit Fantasy 40',
        },
        {
          gameCode: 'wild_double_up',
          name: 'Wild Double Up',
        },
        {
          gameCode: 'mega_juicer_5',
          name: 'Mega Juicer 5',
        },
        {
          gameCode: 'sizzling_sevens',
          name: 'Sizzling Sevens',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'fruit_fantasy_100',
          name: 'Fruit Fantasy 100',
        },
        {
          gameCode: 'joker_juicer_6',
          name: 'Joker Juicer 6',
        },
        {
          gameCode: 'dragon_balls',
          name: 'Dragon Balls',
        },
        {
          gameCode: 'lucky_fruit_spins_bb',
          name: 'Lucky Fruit Spins Bonus Buy',
        },
        {
          gameCode: 'sizzling_super_sevens',
          name: 'Sizzling Super Sevens',
        },
        {
          gameCode: 'book_of_reels',
          name: 'Book of Reels',
        },
        {
          gameCode: 'green_hat_tale',
          name: 'Green Hat Tale',
        },
        {
          gameCode: 'mega_diamonds_hunt',
          name: 'Mega Diamonds Hunt',
        },
        {
          gameCode: 'coin_forge_bb',
          name: 'Coin Forge Bonus Buy',
        },
        {
          gameCode: 'book_of_plenty',
          name: 'Book of Plenty',
        },
        {
          gameCode: 'coin_galactic',
          name: 'Coin Galactic',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
      ],
      termsAndConditions:
        '1. Spin-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Spin-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 3rd May 2024 and 12th May 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 30,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,4 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1800,
        4: 1650,
        5: 1500,
        6: 1200,
        7: 1050,
        8: 900,
        9: 600,
        10: 300,
        '11-20': 210,
        '21-50': 150,
        '51-100': 90,
        '101-150': 60,
        '151-200': 30,
      },
      placeAndPrizeTotal: 30000,
    },
    {
      title: 'Treasure-Topia from Slotopia',
      folderPath: `${promotionsPath}/Treasure-Topia+from+Slotopia/`,
      period: ['04.06.2024 00:01 (UTC)', '10.06.2024 23:59 (UTC)'],
      description:
        'Introducing Treasure-topia from Slotopia! Embark on a thrilling adventure filled with excitement and riches in June. With a generous prize pool of 20,000 EUR up for grabs, this tournament offers the chance to uncover treasures beyond imagination. Join us in Treasure-topia and set sail for an unforgettable gaming experience.',
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Sum Multiplier',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'sizzling_super_sevens',
          name: 'Sizzling Super Sevens',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'dragon_balls',
          name: 'Dragon Balls',
        },
        {
          gameCode: 'wild_double_up',
          name: 'Wild Double Up',
        },
        {
          gameCode: 'coin_forge_magnetic',
          name: 'Coin Forge Magnetic',
        },
        {
          gameCode: 'sizzling_sevens',
          name: 'Sizzling Sevens',
        },
        {
          gameCode: 'joker_juicer_6',
          name: 'Joker Juicer 6',
        },
        {
          gameCode: 'jokers_invasion',
          name: 'Jokers Invasion',
        },
        {
          gameCode: 'mega_juicer_5',
          name: 'Mega Juicer 5',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'book_of_plenty',
          name: 'Book of Plenty',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'fruit_fantasy_40',
          name: 'Fruit Fantasy 40',
        },
        {
          gameCode: 'mega_diamonds_hunt',
          name: 'Mega Diamonds Hunt',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'pyro_joker',
          name: 'Pyro Joker',
        },
        {
          gameCode: 'sizzling_sevens_special',
          name: 'Sizzling Sevens Special',
        },
      ],
      termsAndConditions:
        '1. Treasure-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Treasure-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 4th June 2024 and 10th June 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Sum Multiplier accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Lucky-Topia from Slotopia',
      folderPath: `${promotionsPath}/Lucky-Topia+from+Slotopia/`,
      period: ['18.07.2024 00:01 (UTC)', '24.07.2024 23:59 (UTC)'],
      description:
        'Step into a world of fortune and excitement with the Lucky-Topia from Slotopia Tournament! This July, immerse yourself in a thrilling competition with a dazzling 20,000 EUR prize pool waiting to be claimed. Experience the magic of Slotopia, where luck is on your side, and every spin brings you closer to grand rewards. Don&#39;t miss your chance to join the Lucky-Topia Tournament - let the luck and games begin!',
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Total Spin',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'sizzling_super_sevens',
          name: 'Sizzling Super Sevens',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'wild_double_up',
          name: 'Wild Double Up',
        },
        {
          gameCode: 'coin_galactic',
          name: 'Coin Galactic',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'pyro_joker',
          name: 'Pyro Joker',
        },
      ],
      termsAndConditions:
        '1. Lucky-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Lucky-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 18th July 2024 and 24th July 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Win-Topia from Slotopia',
      folderPath: `${promotionsPath}/Win-Topia+from+Slotopia/`,
      period: ['23.08.2024 00:01 (UTC)', '29.08.2024 23:59 (UTC)'],
      description:
        'It&#39;s the time to catch some rays of fun with Win-Topia from Slotopia Tournament! Invite your players to experience the waves of fun with their all-time favs - Slotopia&#39;s gaming top performers!',
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Total Win',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'coin_forge_magnetic',
          name: 'Coin Forge Magnetic',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'lucky_fruit_spins_bb',
          name: 'Lucky Fruit Spins Bonus Buy',
        },
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'mega_juicer_6',
          name: 'Mega Juicer 6',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'book_of_plenty',
          name: 'Book Of Plenty',
        },
        {
          gameCode: 'sizzling_super_sevens',
          name: 'Sizzling Super Sevens',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'wild_double_up',
          name: 'Wild Double Up',
        },
        {
          gameCode: 'ultra_hot_sevens',
          name: 'Ultra Hot Sevens',
        },
      ],
      termsAndConditions:
        '1. Win-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Win-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 23th August 2024 and 29th August 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Wealth-Topia from Slotopia',
      folderPath: `${promotionsPath}/Wealth-Topia+from+Slotopia+Tournament/`,
      period: ['20.09.2024 00:01 (UTC)', '18.11.2024 23:59 (UTC)'],
      description:
        'Welcome to Wealth-Topia from Slotopia! Running from September to November, this tournament invites you to explore a land of endless riches. With a grand prize pool of 60,000 EUR, Wealth-Topia offers a thrilling opportunity to claim your fortune. Dive into the world of Slotopia and seize the wealth that awaits. Don&#39;t miss your chance to be part of this extraordinary journey to prosperity!',
      prizePool: 60000,
      market: ['Europe'],
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'sizzling_sevens_hold_and_win',
          name: 'Sizzling Sevens Hold & Win',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'fruit_fantasy_100',
          name: 'Fruit Fantasy 100',
        },
        {
          gameCode: 'mega_juicer_6',
          name: 'Mega Juicer 6',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
        {
          gameCode: 'coin_galactic',
          name: 'Coin Galactic',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'pyro_joker',
          name: 'Pyro Joker',
        },
        {
          gameCode: 'sizzling_super_sevens',
          name: 'Sizzling Super Sevens',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'book_of_reels',
          name: 'Book of Reels',
        },
        {
          gameCode: 'candy_luck',
          name: 'Candy Luck',
        },
        {
          gameCode: 'hellfire_joker',
          name: 'Hellfire Joker',
        },
      ],
      termsAndConditions:
        '1. Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Slotopia Tournament consists of 3 (three) Phases, which take place on September 20-26, October 8-14, November 12-18. The Phases start at 00:01 UTC and finish at 23:59 UTC on the corresponding dates.<br/><br/>' +
        '3. The total tournament prize pool amount is 60,000 EUR. Each of three tournaments has its prize pool that will be distributed among the top players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. Each of thee tournaments has its qualified mechanic.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      phases: [
        {
          title: 'Phase 1',
          folderPath: `${promotionsPath}/Wealth-Topia+from+Slotopia+Tournament/campaigns/Phase+1/`,
          period: ['20.09.2024 00:01 (UTC)', '26.09.2024 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Sum Multiplier',
          minBet: 0.5,
          tags: ['Network'],
          placeAndPrize: {
            1: 3000,
            2: 2400,
            3: 1600,
            4: 1400,
            5: 1200,
            6: 900,
            7: 700,
            8: 600,
            9: 400,
            10: 300,
            '11-20': 140,
            '21-50': 70,
            '51-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 2',
          folderPath: `${promotionsPath}/Wealth-Topia+from+Slotopia+Tournament/campaigns/Phase+2/`,
          period: ['8.10.2024 00:01 (UTC)', '14.10.2024 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Total Spin',
          minBet: 0.5,
          tags: ['Network'],
          placeAndPrize: {
            1: 3000,
            2: 2400,
            3: 1600,
            4: 1400,
            5: 1200,
            6: 900,
            7: 700,
            8: 600,
            9: 400,
            10: 300,
            '11-20': 140,
            '21-50': 70,
            '51-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
        {
          title: 'Phase 3',
          folderPath: `${promotionsPath}/Wealth-Topia+from+Slotopia+Tournament/campaigns/Phase+3/`,
          period: ['12.11.2024 00:01 (UTC)', '18.11.2024 23:59 (UTC)'],
          prizePool: 20000,
          mechanic: 'Total Win',
          minBet: 0.5,
          tags: ['Network'],
          placeAndPrize: {
            1: 3000,
            2: 2400,
            3: 1600,
            4: 1400,
            5: 1200,
            6: 900,
            7: 700,
            8: 600,
            9: 400,
            10: 300,
            '11-20': 140,
            '21-50': 70,
            '51-100': 50,
            '101-150': 30,
          },
          placeAndPrizeTotal: 20000,
        },
      ],
    },
    {
      title: 'Festive-Topia from Slotopia',
      folderPath: `${promotionsPath}/Festive-Topia+from+Slotopia/`,
      period: ['17.12.2024 00:01 (UTC)', '26.12.2024 23:59 (UTC)'],
      description:
        'Welcome to Festive-Topia from Slotopia! This December, get ready for a magical tournament filled with holiday spirit and incredible winning opportunities. With a prize pool of 40,000 EUR, Festive-Topia invites you to dive into a world of generosity and joy. Join this unique journey toward festive fortune and don&#39;t miss your chance to be part of this enchanting event!',
      prizePool: 40000,
      market: ['Europe'],
      mechanic: 'Total Spin',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'coin_galactic',
          name: 'Coin Galactic',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'mega_juicer_6',
          name: 'Mega Juicer 6',
        },
        {
          gameCode: 'book_of_plenty',
          name: 'Book Of Plenty',
        },
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'book_of_reels',
          name: 'Book of Reels',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'ultra_hot_sevens',
          name: 'Ultra Hot Sevens',
        },
        {
          gameCode: 'sizzling_sevens_hold_and_win',
          name: 'Sizzling Sevens Hold & Win',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'pyro_joker',
          name: 'Pyro Joker',
        },
        {
          gameCode: 'hellfire_joker',
          name: 'Hellfire Joker',
        },
        {
          gameCode: 'immortal_treasures',
          name: 'Immortal Treasures',
        },
        {
          gameCode: 'candy_luck',
          name: 'Candy Luck',
        },
        {
          gameCode: 'arctic_spins',
          name: 'Arctic Spins',
        },
      ],
      termsAndConditions:
        '1. Festive-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Festive-Topia from Slotopia Tournament runs for ten (10) agreed days and takes place between 00:01 UTC on 17th December 2024 and 26th December 2024 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 40,000 EUR and will be distributed among the top 440 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 5000,
        2: 4500,
        3: 4000,
        4: 3500,
        5: 3000,
        6: 2500,
        7: 2000,
        8: 1500,
        9: 1000,
        10: 500,
        '11-20': 200,
        '21-50': 100,
        '51-90': 50,
        '91-140': 30,
        '141-240': 20,
        '241-440': 10,
      },
      placeAndPrizeTotal: 40000,
    },
    {
      title: 'Ice Boost Slotopia Tournament',
      folderPath: `${promotionsPath}/Ice+Boost+Slotopia+Tournament/`,
      period: ['20.01.2025 00:01 (UTC)', '26.01.2025 23:59 (UTC)'],
      description:
        "Take on the chill in the Ice Boost Slotopia Tournament! This January, gear up for frosty excitement and compete for a cool prize pool of 20,000 EUR. The Ice Boost Tournament brings a refreshing challenge, offering players the chance to turn icy spins into blazing rewards. Don't let the cold stop you-step into the action and claim your frosty fortune!",
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Total Win',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'coin_galactic',
          name: 'Coin Galactic',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'double_up_respin',
          name: 'Double Up Respin',
        },
        {
          gameCode: 'mega_juicer_6',
          name: 'Mega Juicer 6',
        },
        {
          gameCode: 'book_of_plenty',
          name: 'Book Of Plenty',
        },
        {
          gameCode: 'lucky_fruit_spins',
          name: 'Lucky Fruit Spins',
        },
        {
          gameCode: 'book_of_reels',
          name: 'Book of Reels',
        },
        {
          gameCode: 'green_hat_magic',
          name: 'Green Hat Magic',
        },
        {
          gameCode: 'mega_fruit_100',
          name: 'Mega Fruit 100',
        },
        {
          gameCode: 'pepper_mania',
          name: 'Pepper Mania',
        },
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'ultra_hot_sevens',
          name: 'Ultra Hot Sevens',
        },
        {
          gameCode: 'sizzling_sevens_hold_and_win',
          name: 'Sizzling Sevens Hold & Win',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'hellfire_joker',
          name: 'Hellfire Joker',
        },
        {
          gameCode: 'immortal_treasures',
          name: 'Immortal Treasures',
        },
        {
          gameCode: 'candy_luck',
          name: 'Candy Luck',
        },
        {
          gameCode: 'arctic_spins',
          name: 'Arctic Spins',
        },
      ],
      termsAndConditions:
        '1. Ice Boost Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Ice Boost Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 20th January 2025 and 26th January 2025 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 200 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Win accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 20,
        '151-200': 10,
      },
      placeAndPrizeTotal: 20000,
    },
    {
      title: 'Gift-Topia from Slotopia',
      folderPath: `${promotionsPath}/Gift-Topia+from+Slotopia/`,
      period: ['11.02.2025 00:01 (UTC)', '17.02.2025 23:59 (UTC)'],
      description:
        "Unwrap the excitement in the Gift-Topia Tournament from Slotopia! This February, dive into a world of surprises and compete for a delightful prize pool of 20,000 EUR. The Gift-Topia Tournament invites you to celebrate the season of giving with thrilling gameplay and generous rewards. Don't miss your chance to claim the gifts waiting for you!",
      prizePool: 20000,
      market: ['Europe'],
      mechanic: 'Total Win',
      minBet: 0.5,
      languages: ['en'],
      tags: ['Network'],
      gamesParticipating: [
        {
          gameCode: 'diamonds_hunt',
          name: 'Diamonds Hunt',
        },
        {
          gameCode: 'ultra_hot_sevens',
          name: 'Ultra Hot Sevens',
        },
        {
          gameCode: 'coin_meteor',
          name: 'Coin Meteor',
        },
        {
          gameCode: 'hellfire_joker',
          name: 'Hellfire Joker',
        },
        {
          gameCode: 'fishing_mania',
          name: 'Fishing Mania',
        },
        {
          gameCode: 'ultra_coin_magnetic_bb',
          name: 'Ultra Coin Magnetic Bonus Buy',
        },
        {
          gameCode: 'coin_galactic',
          name: 'Coin Galactic',
        },
        {
          gameCode: 'diamonds_expand',
          name: 'Diamonds Expand',
        },
        {
          gameCode: 'candy_luck',
          name: 'Candy Luck',
        },
        {
          gameCode: 'mega_fishing_mania',
          name: 'Mega Fishing Mania',
        },
      ],
      termsAndConditions:
        '1. Gift-Topia from Slotopia Tournament is available to play in real mode only.<br/><br/>' +
        '2. Gift-Topia from Slotopia Tournament runs for seven (7) agreed days and takes place between 00:01 UTC on 11th February 2025 and 17th February 2025 until 23:59 UTC.<br/><br/>' +
        '3. The total tournament prize amount is 20,000 EUR and will be distributed among the top 150 players who have the highest scores on the leaderboards.<br/><br/>' +
        '4. The leaderboard is consolidated for all operators participating in this network tournament.<br/><br/>' +
        '5. The minimum bet required to participate in the tournament is 0,5 EUR or the equivalent in the local currency and might be subject to currency conversion fluctuations.<br/><br/>' +
        '6. All prizes are deducted from the GGR before invoicing regardless of the percentage of Bonus Deduction according to the contract.<br/><br/>' +
        '7. The prize pool and minimum bet for this campaign are set in EUR and might be subject to currency conversion fluctuations.<br/><br/>' +
        '8. The tournament has a list of qualified games and a prize pool (EUR), which is distributed among the winners according to the tournament rules.<br/><br/>' +
        '9. The tournament has its qualified mechanic: Total Spin accumulation.<br/><br/>' +
        '10. Every qualified spin will contribute to the player&#39;s total score that is shown on the leaderboard. The total score is calculated with the points gained during all rounds played in participating games. The more points, the higher the player gets on the leaderboard. <br/><br/>' +
        '11. If several participants gain the same number of points, then a higher position in the leaderboard is given to the participant who scored first. <br/><br/>' +
        '12. Once the tournament is over, every eligible operator will receive the list of the winners to credit the prizes.<br/><br/>' +
        '13. The cash prizes will be credited to the players&#39; accounts as withdrawable funds by the casino after the end of promotion.<br/><br/>' +
        '14. Slotopia reserves the right to void scores, or not pay out a prize, where all or part of the score results from any obvious error, mistake, or technical fault (including incorrect game payouts) caused by a machine or human error to any of the participating games.<br/><br/>' +
        '15. Slotopia further reserves the right to void scores, or not pay out a prize where, in opinion,  all part of the score results from cheating or collusion with other players.<br/><br/>' +
        '16. The compensation system is calculated in accordance with the prize pool and set amount of participating leaders.<br/><br/>' +
        '17. A casino must support the tournament by implementing the list of marketing activities.<br/><br/>' +
        '18. Players will receive a pop-up announcement about the start of the tournament two (2) days before the tournament.<br/><br/>' +
        '19. Submit your participation in the tournament minimum seven (7) days before the start of the tournament.<br/><br/>',
      placeAndPrize: {
        1: 3000,
        2: 2400,
        3: 1600,
        4: 1400,
        5: 1200,
        6: 900,
        7: 700,
        8: 600,
        9: 400,
        10: 300,
        '11-20': 140,
        '21-50': 70,
        '51-100': 50,
        '101-150': 30,
      },
      placeAndPrizeTotal: 20000,
    },
  ],
};
